import React from 'react';
import { FormattedMessage } from 'react-intl';

import error from "../Images/error.png";

const Error = () => {
    return (
        <div className='container row center' style={{display:"flex", alignItems:"center", flexWrap:"wrap", padding:"50px 0"}}>
            <div className='col s12 m12 l6' style={{paddingBottom:"50px"}}>
                <p className='titulo_error'>404</p>
                <p className='titulo_2' style={{color:"#235677"}}><b>
                    <FormattedMessage
                        id="error.1"
                        defaultMessage="Página no encontrada"
                    /></b>
                </p>
                <p>
                    <FormattedMessage
                        id="error.2"
                        defaultMessage="La página a la que intentas acceder cambió,"
                    /><br />
                    <b><a href="/" style={{textDecoration:"underline", color:"#235677"}}>
                        <FormattedMessage
                            id="error.3"
                            defaultMessage="vuelve al inicio"
                        />
                    </a></b>
                    <FormattedMessage
                        id="error.4"
                        defaultMessage=" o  agenda tu cita."
                    />
                </p>
                <div style={{display:"flex", gap:"15px", justifyContent:"center", marginTop:"20px"}}>
                    <div className="boton">
                        <a style={{height:"32px", padding:"0 15px", display:"flex", justifyContent:"center", alignItems:"center"}} href="/contacto-guadalajara">
                            <span>
                                <FormattedMessage
                                    id="header.8"
                                    defaultMessage="cita"
                                />
                                {" "}Guadalajara
                            </span>
                        </a>
                    </div>
                    <div className="boton">
                        <a style={{height:"32px", padding:"0 15px", display:"flex", justifyContent:"center", alignItems:"center"}} href="/contacto-vallarta">
                            <span>
                                <FormattedMessage
                                    id="header.8"
                                    defaultMessage="cita"
                                />
                                {" "}Nv. Vallarta
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div className='col s12 m12 l6'>
                <img src={error} alt="Error, pagina no encontrada oftalretina" style={{width:"100%"}} />
            </div>
        </div>
    );
};

export default Error;