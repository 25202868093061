import React from 'react';
import { FormattedMessage } from 'react-intl';
import gracias from '../Images/gracias.svg';

const Agradeciminto = () => {
    return (
        <div className='container row' style={{height:"59vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <div className='col s12 m12 l6 center'>
                <h1 className='titulo_2' style={{marginTop:"0px", fontWeight:"bolder"}}>
                    <FormattedMessage
                        id="agradecimiento.1"
                        defaultMessage="Envío exitoso"
                    />
                </h1>
                <p>
                    <FormattedMessage
                        id="agradecimiento.2"
                        defaultMessage="Gracias por completar el formulario con tus datos."
                    /><br/>
                    <FormattedMessage
                        id="agradecimiento.3"
                        defaultMessage="En breve, nos pondremos en contacto contigo."
                    />
                </p>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <a href="/">
                        <div className='boton' style={{padding:"5px 10px"}}>
                            <p style={{margin:"0 15px"}}>
                                <FormattedMessage
                                    id="agradecimiento.4"
                                    defaultMessage="Volver al inicio"
                                />
                        </p>
                        </div>
                    </a>
                </div>
            </div>
            <div className='col s12 m12 l6'>
                <img src={gracias} alt="paloma verde" style={{width:"100%"}} />
            </div>
        </div>
    );
};

export default Agradeciminto;