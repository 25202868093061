import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import Header from '../../../Components/SideNav';
import firebaseHelper from '../../../Helpers/Firebase';
import Switch from "react-switch";
import M from "materialize-css";

class Horarios extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            dias_semana: [],
            state_carga: true,
            lunes: false,
            martes: false,
            miercoles: false,
            jueves: false,
            viernes: false,
            sabado: false,
            domingo: false,
            horas: ['0:00', '0:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
            target_lunes: [],
            target_martes: [],
            target_miercoles: [],
            target_jueves: [],
            target_viernes: [],
            target_sabado: [],
            target_domingo: [],
            horario_lunes: [],
            horario_martes: [],
            horario_miercoles: [],
            horario_jueves: [],
            horario_viernes: [],
            horario_sabado: [],
            horario_domingo: [],
        }
    }

    componentDidUpdate = () => {
        if (this.state.state_carga && this.props.horarios) {
            this.setState({
                lunes: this.props.horarios.lunes,
                horario_lunes: this.props.horarios.horario_lunes,
                target_lunes: this.props.horarios.horario_lunes && this.props.horarios.horario_lunes.length !== 0 ? (
                    this.props.horarios.horario_lunes.map((dato_horarios, index_horarios) => (
                        index_horarios > 0 ? (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="borrar_horario" id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2 center">
                                    <i class="fas fa-times" onClick={this.eliminar}></i>
                                </div>
                            </div>
                        ) : (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2"></div>
                            </div>
                        )
                    ))
                ) : (
                    [<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div class="input-field col s5">
                            <select name="inicio" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        :
                        <div class="input-field col s5">
                            <select name="fin" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col s2"></div>
                    </div>]
                ),
                martes: this.props.horarios.martes,
                horario_martes: this.props.horarios.horario_martes,
                target_martes: this.props.horarios.horario_martes && this.props.horarios.horario_martes.length !== 0 ? (
                    this.props.horarios.horario_martes.map((dato_horarios, index_horarios) => (
                        index_horarios > 0 ? (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="borrar_horario" id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2 center">
                                    <i class="fas fa-times" onClick={this.eliminar}></i>
                                </div>
                            </div>
                        ) : (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2"></div>
                            </div>
                        )
                    ))
                ) : (
                    [<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div class="input-field col s5">
                            <select name="inicio" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        :
                        <div class="input-field col s5">
                            <select name="fin" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col s2"></div>
                    </div>]
                ),
                miercoles: this.props.horarios.miercoles,
                horario_miercoles: this.props.horarios.horario_miercoles,
                target_miercoles: this.props.horarios.horario_miercoles && this.props.horarios.horario_miercoles.length !== 0 ? (
                    this.props.horarios.horario_miercoles.map((dato_horarios, index_horarios) => (
                        index_horarios > 0 ? (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="borrar_horario" id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2 center">
                                    <i class="fas fa-times" onClick={this.eliminar}></i>
                                </div>
                            </div>
                        ) : (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2"></div>
                            </div>
                        )
                    ))
                ) : (
                    [<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div class="input-field col s5">
                            <select name="inicio" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        :
                        <div class="input-field col s5">
                            <select name="fin" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col s2"></div>
                    </div>]
                ),
                jueves: this.props.horarios.jueves,
                horario_jueves: this.props.horarios.horario_jueves,
                target_jueves: this.props.horarios.horario_jueves && this.props.horarios.horario_jueves.length !== 0 ? (
                    this.props.horarios.horario_jueves.map((dato_horarios, index_horarios) => (
                        index_horarios > 0 ? (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="borrar_horario" id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2 center">
                                    <i class="fas fa-times" onClick={this.eliminar}></i>
                                </div>
                            </div>
                        ) : (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2"></div>
                            </div>
                        )
                    ))
                ) : (
                    [<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div class="input-field col s5">
                            <select name="inicio" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        :
                        <div class="input-field col s5">
                            <select name="fin" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col s2"></div>
                    </div>]
                ),
                viernes: this.props.horarios.viernes,
                horario_viernes: this.props.horarios.horario_viernes,
                target_viernes: this.props.horarios.horario_viernes && this.props.horarios.horario_viernes.length !== 0 ? (
                    this.props.horarios.horario_viernes.map((dato_horarios, index_horarios) => (
                        index_horarios > 0 ? (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="borrar_horario" id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2 center">
                                    <i class="fas fa-times" onClick={this.eliminar}></i>
                                </div>
                            </div>
                        ) : (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2"></div>
                            </div>
                        )
                    ))
                ) : (
                    [<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div class="input-field col s5">
                            <select name="inicio" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        :
                        <div class="input-field col s5">
                            <select name="fin" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col s2"></div>
                    </div>]
                ),
                sabado: this.props.horarios.sabado,
                horario_sabado: this.props.horarios.horario_sabado,
                target_sabado: this.props.horarios.horario_sabado && this.props.horarios.horario_sabado.length ? (
                    this.props.horarios.horario_sabado.map((dato_horarios, index_horarios) => (
                        index_horarios > 0 ? (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="borrar_horario" id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2 center">
                                    <i class="fas fa-times" onClick={this.eliminar}></i>
                                </div>
                            </div>
                        ) : (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2"></div>
                            </div>
                        )
                    ))
                ) : (
                    [<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div class="input-field col s5">
                            <select name="inicio" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        :
                        <div class="input-field col s5">
                            <select name="fin" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col s2"></div>
                    </div>]
                ),
                domingo: this.props.horarios.domingo,
                horario_domingo: this.props.horarios.horario_domingo,
                target_domingo: this.props.horarios.horario_domingo && this.props.horarios.horario_domingo.length ? (
                    this.props.horarios.horario_domingo.map((dato_horarios, index_horarios) => (
                        index_horarios > 0 ? (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="borrar_horario" id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2 center">
                                    <i class="fas fa-times" onClick={this.eliminar}></i>
                                </div>
                            </div>
                        ) : (
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} id={index_horarios}>
                                <div class="input-field col s5">
                                    <select name="inicio" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.inicio) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                :
                                <div class="input-field col s5">
                                    <select name="fin" onChange={this.leerHora}>
                                        <option value="" disabled selected>Hora</option>
                                        {this.state.horas.map((dato, index) => (
                                            index === parseInt(dato_horarios.fin) ? (
                                                <option selected value={index}>{dato}</option>
                                            ) : (
                                                <option value={index}>{dato}</option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="col s2"></div>
                            </div>
                        )
                    ))
                ) : (
                    [<div style={{display:"flex", justifyContent:"center", alignItems:"center"}} id = "0">
                        <div class="input-field col s5">
                            <select name="inicio" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        :
                        <div class="input-field col s5">
                            <select name="fin" onChange={this.leerHora}>
                                <option value="" disabled selected>Hora</option>
                                {this.state.horas.map((dato, index) => (
                                    <option value={index}>{dato}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col s2"></div>
                    </div>]
                ),
                state_carga: false
            }, () => {
                this.actualizar()
            });
        }
    }

    eliminar = e => {
        e.preventDefault();
        var name_padre = e.target.parentNode.parentNode.parentNode.className;
        var id = parseInt(e.target.parentNode.parentNode.id);
        var target_state, horario_state;

        switch (name_padre) {
            case "lunes":
                target_state = this.state.target_lunes;
                target_state.splice(id , 1);
                horario_state = [...this.state.horario_lunes];
                horario_state.splice(id , 1);
                this.setState({
                    target_lunes: target_state,
                    horario_lunes: horario_state
                });
                break;
            case "martes":
                target_state = this.state.target_martes;
                target_state.splice(id , 1);
                horario_state = [...this.state.horario_martes];
                horario_state.splice(id , 1);
                this.setState({
                    target_martes: target_state,
                    horario_martes: horario_state
                });
                break;
            case "miercoles":
                target_state = this.state.target_miercoles;
                target_state.splice(id , 1);
                horario_state = [...this.state.horario_miercoles];
                horario_state.splice(id , 1);
                this.setState({
                    target_miercoles: target_state,
                    horario_miercoles: horario_state
                });
                break;
            case "jueves":
                target_state = this.state.target_jueves;
                target_state.splice(id , 1);
                horario_state = [...this.state.horario_jueves];
                horario_state.splice(id , 1);
                this.setState({
                    target_jueves: target_state,
                    horario_jueves: horario_state
                });
                break;
            case "viernes":
                target_state = this.state.target_viernes;
                target_state.splice(id , 1);
                horario_state = [...this.state.horario_viernes];
                horario_state.splice(id , 1);
                this.setState({
                    target_viernes: target_state,
                    horario_viernes: horario_state
                });
                break;
            case "sabado":
                target_state = this.state.target_sabado;
                target_state.splice(id , 1);
                horario_state = [...this.state.horario_sabado];
                horario_state.splice(id , 1);
                this.setState({
                    target_sabado: target_state,
                    horario_sabado: horario_state
                });
                break;
            case "domingo":
                target_state = this.state.target_domingo;
                target_state.splice(id , 1);
                horario_state = [...this.state.horario_domingo];
                horario_state.splice(id , 1);
                this.setState({
                    target_domingo: target_state,
                    horario_domingo: horario_state
                });
                break;
        }

    }

    agregar = e => {
        const { history } = this.props;
        e.preventDefault();

        var editar = {
            dias_semana: this.state.dias_semana,
            horario_domingo: this.state.horario_domingo,
            horario_sabado: this.state.horario_sabado,
            horario_viernes: this.state.horario_viernes,
            horario_jueves: this.state.horario_jueves,
            horario_miercoles: this.state.horario_miercoles,
            horario_martes: this.state.horario_martes,
            horario_lunes: this.state.horario_lunes,
            lunes: this.state.lunes,
            martes: this.state.martes,
            miercoles: this.state.miercoles,
            jueves: this.state.jueves,
            viernes: this.state.viernes,
            sabado: this.state.sabado,
            domingo: this.state.domingo
        }
        
        //extraer firestor de props
        const { firestore } = this.props;

        //guardado en la base de datos
        firebaseHelper.editar("2rFyHG8As9UCaARJlpVX", 'horarios', firestore, editar, '/admin/horarios', history);

    }

    leerDato = (value, dia) => {
        var dias_semana = this.state.dias_semana;
        const busqueda = dias_semana.find(dato => dato === value);
        var dia;
        if (busqueda) {
            const index =  dias_semana.indexOf(value);
            dias_semana.splice(index, 1)
        } else {
            dias_semana.push(value);
        }
        switch (value) {
            case "0":
                dia = {
                    dias_semana,
                    lunes: !dia
                }
                break;
            case "1":
                dia = {
                    dias_semana,
                    martes: !dia
                }
                break;
            case "2":
                dia = {
                    dias_semana,
                    miercoles: !dia
                }
                break;
            case "3":
                dia = {
                    dias_semana,
                    jueves: !dia
                }
                break;
            case "4":
                dia = {
                    dias_semana,
                    viernes: !dia
                }
                break;
            case "5":
                dia = {
                    dias_semana,
                    sabado: !dia
                }
                break;
            case "6":
                dia = {
                    dias_semana,
                    domingo: !dia
                }
                break;
        }

        this.setState(dia, () => {
            var elems = document.querySelectorAll('select');
            M.FormSelect.init(elems);
        })
    }

    agregarHorario = e => {
        e.preventDefault();
        var stateTarget;
        var length_horario;

        switch (e.target.parentNode.className) {
            case "lunes":
                length_horario = this.state.horario_lunes.length
                break;
            case "martes":
                length_horario = this.state.horario_martes.length
                break;
            case "miercoles":
                length_horario = this.state.horario_miercoles.length
                break;
            case "jueves":
                length_horario = this.state.horario_jueves.length
                break;
            case "viernes":
                length_horario = this.state.horario_viernes.length
                break;
            case "sabado":
                length_horario = this.state.horario_sabado.length
                break;
            case "domingo":
                length_horario = this.state.horario_domingo.length
                break;
        }
        var target = 
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="borrar_horario" id={length_horario}>
                <div class="input-field col s5">
                    <select name="inicio" onChange={this.leerHora}>
                        <option value="" disabled selected>Hora</option>
                        {this.state.horas.map((dato, index) => (
                            <option value={index}>{dato}</option>
                        ))}
                    </select>
                </div>
                :
                <div class="input-field col s5">
                    <select name="fin" onChange={this.leerHora}>
                        <option value="" disabled selected>Hora</option>
                        {this.state.horas.map((dato, index) => (
                            <option value={index}>{dato}</option>
                        ))}
                    </select>
                </div>
                <div className="col s2 center">
                    <i class="fas fa-times" onClick={this.eliminar}></i>
                </div>
            </div>;

        switch (e.target.parentNode.className) {
            case "lunes":
                stateTarget = this.state.target_lunes;
                stateTarget.push(target);
                this.setState({
                    target_lunes: stateTarget
                }, () => {
                    this.actualizar()
                });
                break;
            case "martes":
                stateTarget = this.state.target_martes;
                stateTarget.push(target);
                this.setState({
                    target_martes: stateTarget
                }, () => {
                    this.actualizar()
                });
                break;
            case "miercoles":
                stateTarget = this.state.target_miercoles;
                stateTarget.push(target);
                this.setState({
                    target_miercoles: stateTarget
                }, () => {
                    this.actualizar()
                });
                break;
            case "jueves":
                stateTarget = this.state.target_jueves;
                stateTarget.push(target);
                this.setState({
                    target_jueves: stateTarget
                }, () => {
                    this.actualizar()
                });
                break;
            case "viernes":
                stateTarget = this.state.target_viernes;
                stateTarget.push(target);
                this.setState({
                    target_viernes: stateTarget
                }, () => {
                    this.actualizar()
                });
                break;
            case "sabado":
                stateTarget = this.state.target_sabado;
                stateTarget.push(target);
                this.setState({
                    target_sabado: stateTarget
                }, () => {
                    this.actualizar()
                });
                break;
            case "domingo":
                stateTarget = this.state.target_domingo;
                stateTarget.push(target);
                this.setState({
                    target_domingo: stateTarget
                }, () => {
                    this.actualizar()
                });
                break;
        }
        
    }

    actualizar = () => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }

    leerHora = e => {
        e.preventDefault();
        var name = e.target.name;
        var name_padre = e.target.parentNode.parentNode.parentNode.parentNode.className
        var hora_state, hora_state_array;

        switch (name_padre) {
            case "lunes":
                hora_state = {...this.state.horario_lunes[parseInt(e.target.parentNode.parentNode.parentNode.id)]};
                hora_state_array= [...this.state.horario_lunes];
                break;
            case "martes":
                hora_state = {...this.state.horario_martes[parseInt(e.target.parentNode.parentNode.parentNode.id)]};
                hora_state_array= [...this.state.horario_martes];
                break;
            case "miercoles":
                hora_state = {...this.state.horario_miercoles[parseInt(e.target.parentNode.parentNode.parentNode.id)]};
                hora_state_array= [...this.state.horario_miercoles];
                break;
            case "jueves":
                hora_state = {...this.state.horario_jueves[parseInt(e.target.parentNode.parentNode.parentNode.id)]};
                hora_state_array= [...this.state.horario_jueves];
                break;
            case "viernes":
                hora_state = {...this.state.horario_viernes[parseInt(e.target.parentNode.parentNode.parentNode.id)]};
                hora_state_array= [...this.state.horario_viernes];
                break;
            case "sabado":
                hora_state = {...this.state.horario_sabado[parseInt(e.target.parentNode.parentNode.parentNode.id)]};
                hora_state_array= [...this.state.horario_sabado];
                break;
            case "domingo":
                hora_state = {...this.state.horario_domingo[parseInt(e.target.parentNode.parentNode.parentNode.id)]};
                hora_state_array= [...this.state.horario_domingo];
                break;
        }

        if (JSON.stringify(hora_state) === '{}') {
            name === "inicio" ? hora_state_array.push({fin: "", inicio: e.target.value}) : hora_state_array.push({inicio: "", fin: e.target.value})
        } else {
            name === "inicio" ? hora_state.inicio = e.target.value : hora_state.fin = e.target.value
            hora_state_array[parseInt(e.target.parentNode.parentNode.parentNode.id)] = hora_state;
        }

        switch (name_padre) {
            case "lunes":
                this.setState({
                    horario_lunes: hora_state_array
                });
                break;
            case "martes":
                this.setState({
                    horario_martes: hora_state_array
                });
                break;
            case "miercoles":
                this.setState({
                    horario_miercoles: hora_state_array
                });
                break;
            case "jueves":
                this.setState({
                    horario_jueves: hora_state_array
                });
                break;
            case "viernes":
                this.setState({
                    horario_viernes: hora_state_array
                });
                break;
            case "sabado":
                this.setState({
                    horario_sabado: hora_state_array
                });
                break;
            case "domingo":
                this.setState({
                    horario_domingo: hora_state_array
                });
                break;
        }
    }

    render(){
        return (
            <Fragment>
                <Header mensaje="HORARIOS" />
                <div id="font" className="container">    
                    <div style={{padding: '30px 0 30px 0'}}>
                        <form onSubmit={this.agregar}>
                            <div className="row" style={{margin:'0 15%'}}>
                                <div className="col s12" style={{margin:"30px 0px"}}>
                                    <div className="col s4" style={{margin:"30px 0px"}}>
                                        <label>
                                            <Switch 
                                                onChange={() => {this.leerDato("0", this.state.lunes)}} 
                                                checked={this.state.lunes}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                            />
                                            <span style={{fontSize:"20px", position:"relative", top:"-9px", margin:"10px"}}>Lunes</span>
                                        </label>
                                    </div>
                                    <div className="col s8">
                                        {this.state.lunes ? (
                                            <div className="lunes">
                                                {this.state.target_lunes.map(dato => (
                                                    dato
                                                ))}
                                                <button className="btn" onClick={this.agregarHorario}>
                                                    agregar horario
                                                </button>
                                            </div>
                                        ) : (<span></span>)}
                                    </div>
                                </div>
                                <div className="col s12" style={{margin:"30px 0px"}}>
                                    <div className="col s4" style={{margin:"30px 0px"}}>
                                        <label>
                                            <Switch 
                                                onChange={() => {this.leerDato("1", this.state.martes)}} 
                                                checked={this.state.martes} 
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                            />
                                            <span style={{fontSize:"20px", position:"relative", top:"-9px", margin:"10px"}}>Martes</span>
                                        </label>
                                    </div>
                                    <div className="col s8">
                                        {this.state.martes ? (
                                            <div className="martes">
                                                {this.state.target_martes.map(dato => (
                                                    dato
                                                ))}
                                                <button className="btn" onClick={this.agregarHorario}>
                                                    agregar horario
                                                </button>
                                            </div>
                                        ) : (<span></span>)}
                                    </div>
                                </div>
                                <div className="col s12" style={{margin:"30px 0px"}}>
                                    <div className="col s4" style={{margin:"30px 0px"}}>
                                        <label>
                                            <Switch 
                                                onChange={() => {this.leerDato("2", this.state.miercoles)}} 
                                                checked={this.state.miercoles} 
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                            />
                                            <span style={{fontSize:"20px", position:"relative", top:"-9px", margin:"10px"}}>Miercoles</span>
                                        </label>
                                    </div>
                                    <div className="col s8">
                                        {this.state.miercoles ? (
                                            <div className="miercoles">
                                                {this.state.target_miercoles.map(dato => (
                                                    dato
                                                ))}
                                                <button className="btn" onClick={this.agregarHorario}>
                                                    agregar horario
                                                </button>
                                            </div>
                                        ) : (<span></span>)}
                                    </div>
                                </div>
                                <div className="col s12" style={{margin:"30px 0px"}}>
                                    <div className="col s4" style={{margin:"30px 0px"}}>
                                        <label>
                                            <Switch 
                                                onChange={() => {this.leerDato("3", this.state.jueves)}} 
                                                checked={this.state.jueves} 
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                            />
                                            <span style={{fontSize:"20px", position:"relative", top:"-9px", margin:"10px"}}>Jueves</span>
                                        </label>
                                    </div>
                                    <div className="col s8">
                                        {this.state.jueves ? (
                                            <div className="jueves">
                                                {this.state.target_jueves.map(dato => (
                                                    dato
                                                ))}
                                                <button className="btn" onClick={this.agregarHorario}>
                                                    agregar horario
                                                </button>
                                            </div>
                                        ) : (<span></span>)}
                                    </div>
                                </div>
                                <div className="col s12" style={{margin:"30px 0px"}}>
                                    <div className="col s4" style={{margin:"30px 0px"}}>
                                        <label>
                                            <Switch 
                                                onChange={() => {this.leerDato("4", this.state.viernes)}} 
                                                checked={this.state.viernes} 
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                            />
                                            <span style={{fontSize:"20px", position:"relative", top:"-9px", margin:"10px"}}>Viernes</span>
                                        </label>
                                    </div>
                                    <div className="col s8">
                                        {this.state.viernes ? (
                                            <div className="viernes">
                                                {this.state.target_viernes.map(dato => (
                                                    dato
                                                ))}
                                                <button className="btn" onClick={this.agregarHorario}>
                                                    agregar horario
                                                </button>
                                            </div>
                                        ) : (<span></span>)}
                                    </div>
                                </div>
                                <div className="col s12" style={{margin:"30px 0px"}}>
                                    <div className="col s4" style={{margin:"30px 0px"}}>
                                        <label>
                                            <Switch 
                                                onChange={() => {this.leerDato("5", this.state.sabado)}} 
                                                checked={this.state.sabado} 
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                            />
                                            <span style={{fontSize:"20px", position:"relative", top:"-9px", margin:"10px"}}>Sabado</span>
                                        </label>
                                    </div>
                                    <div className="col s8">
                                        {this.state.sabado ? (
                                            <div className="sabado">
                                                {this.state.target_sabado.map(dato => (
                                                    dato
                                                ))}
                                                <button className="btn" onClick={this.agregarHorario}>
                                                    agregar horario
                                                </button>
                                            </div>
                                        ) : (<span></span>)}
                                    </div>
                                </div>
                                <div className="col s12" style={{margin:"30px 0px"}}>
                                    <div className="col s4" style={{margin:"30px 0px"}}>
                                        <label>
                                            <Switch 
                                                onChange={() => {this.leerDato("6", this.state.domingo)}} 
                                                checked={this.state.domingo} 
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                            />
                                            <span style={{fontSize:"20px", position:"relative", top:"-9px", margin:"10px"}}>Domingo</span>
                                        </label>
                                    </div>
                                    <div className="col s8">
                                        {this.state.domingo ? (
                                            <div className="domingo">
                                                {this.state.target_domingo.map(dato => (
                                                    dato
                                                ))}
                                                <button className="btn" onClick={this.agregarHorario}>
                                                    agregar horario
                                                </button>
                                            </div>
                                        ) : (<span></span>)}
                                    </div>
                                </div>
                            </div>
                            <div className="center" style={{margin:"20px 0px 100px 0px"}}>
                                <button id="font-button" class="btn" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#235677 '}}>GUARDAR</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
}

Horarios.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'horarios',
            doc: "2rFyHG8As9UCaARJlpVX"
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        horarios: ordered.horarios && ordered.horarios[0]
    }))
)(Horarios)