import React from 'react';

// import ReactDOM from 'react-dom';

import { createRoot } from "react-dom/client";
import './index.css';
import './datepicker.css';
import Router from './Router/Router';
import {LangProvider} from './Context/langContext';

import { Provider } from 'react-redux'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore' // <- needed if using firestore
// import 'firebase/functions' // <- needed if using httpsCallable

import { createStore, combineReducers } from 'redux'
import {
  ReactReduxFirebaseProvider,
  firebaseReducer
} from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore

const fbConfig = {
  apiKey: "AIzaSyA5HxC_Mhj099k-roKH9XIZ1xjQGdMCymY",
  authDomain: "oftalretina-e916e.firebaseapp.com",
  projectId: "oftalretina-e916e",
  storageBucket: "oftalretina-e916e.appspot.com",
  messagingSenderId: "108828702843",
  appId: "1:108828702843:web:7334b4fc7519810dfb8ade",
  measurementId: "G-00PGQ1T6YV"
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore
})

// Create store with reducers and initial state
const initialState = {}
const store = createStore(rootReducer, initialState)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <LangProvider>
          <Router />
        </LangProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
