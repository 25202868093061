import React, {Fragment, useContext, useState, useEffect}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';
import Header from '../../../Components/SideNav';

import InfiniteCalendar, {defaultMultipleDateInterpolation, withMultipleDates, Calendar}  from '@appannie/react-infinite-calendar';
import '@appannie/react-infinite-calendar/styles.css'; // Make sure to import the default stylesheet


const Calendario = props => {
    const [state, setState] = useState({
        diasVallarta: [],
        diasGuadalajara: []
    });

    let diasVallartaGlobal = [];
    let diasGuadalajaraGlobal = [];

    useEffect(() => {
        if (state.diasGuadalajara.length === 0 && state.diasVallarta.length === 0 && props.horarios) {
            var diasVallarta = [], diasGuadalajara = [];
            for (let index = 0; index < props.horarios.diasVallarta.length; index++) {
                diasVallarta.push(new Date(props.horarios.diasVallarta[index].seconds * 1000));
            }
            for (let index = 0; index < props.horarios.diasGuadalajara.length; index++) {
                diasGuadalajara.push(new Date(props.horarios.diasGuadalajara[index].seconds * 1000));
            }

            setState({
                diasVallarta,
                diasGuadalajara
            });

        }

        setTimeout(() => {
            window.scroll(0,0);
        }, 1);
    }, [props]);

    const seleccionarVallarta = (date) => {
        var data = [];
        var indice = -1;

        if(diasVallartaGlobal.length === 0){
            diasVallartaGlobal = state.diasVallarta;
        }

        for (let index = 0; index < diasVallartaGlobal.length; index++) {
            data.push(diasVallartaGlobal[index])
        }
        
        indice = data.map(Number).indexOf(+date);
        if (indice !== -1) {
            data.splice(indice, 1);
        } else {
            data.push(date)
        }
        diasVallartaGlobal = data;
    }

    const seleccionarGuadalajara = (date) => {
        console.log("entro");
        var data = [];
        var indice = -1;

        if(diasGuadalajaraGlobal.length === 0){
            diasGuadalajaraGlobal = state.diasGuadalajara;
        }

        for (let index = 0; index < diasGuadalajaraGlobal.length; index++) {
            data.push(diasGuadalajaraGlobal[index])
        }
        
        indice = data.map(Number).indexOf(+date);
        if (indice !== -1) {
            data.splice(indice, 1);
        } else {
            data.push(date)
        }
        diasGuadalajaraGlobal = data;
    }

    const actualizar = e => {
        e.preventDefault();
        const { history } = props;

        if(diasVallartaGlobal.length === 0){
            diasVallartaGlobal = state.diasVallarta;
        }

        if(diasGuadalajaraGlobal.length === 0){
            diasGuadalajaraGlobal = state.diasGuadalajara;
        }

        //extraer valores del state
        var editar = {
            diasVallarta: diasVallartaGlobal,
            diasGuadalajara: diasGuadalajaraGlobal
        }
    
        //extraer firestor de props
        const { firestore } = props;

        //actualizacion en la base de datos
        firebaseHelper.editar(props.horarios.id, 'horarios', firestore, editar, '/admin/calendario', history);
    }

    if (state.diasGuadalajara.length === 0 && state.diasVallarta.length === 0) {
        return (
            <Fragment>
                <Header />
                <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-red darken-4-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <Header mensaje="CONFIGURACION CALENDARIO" />

                <div className="container" style={{marginBottom:"100px"}}>
                    <button id="font-button" class="btn" onClick={actualizar} style={{padding:'0 50px 0 50px', background:'#235677 '}}>ACTUALIZAR</button>

                    <p style={{fontSize:"20px"}}>Seleccione los días que se bloqueran en el calendario.</p>
                    <p className="titulo_1">Calendario Vallarta</p>
                    <InfiniteCalendar
                        //selector de fechas
                        Component={withMultipleDates(Calendar)}
                        selected={state.diasVallarta}
                        interpolateSelection={defaultMultipleDateInterpolation}
                        //fin selector de fechas
                        
                        //configuracion lenguaje
                        locale={{
                            weekStartsOn: 1,
                            weekdays: ["Dom","Lun","Mar","Mir","Jue","Vie","Sab"],
                            blank: 'Sin fecha seleccionada',
                            todayLabel: {
                                long: 'Hoy dia\'hoy',
                                short: 'Hoy'
                            }
                        }}
                        //fin configuracion lenguaje
                        //tamanio
                        width={"100%"}
                        height={window.innerHeight - 250}
                        rowHeight={70}
                        //fin tamanio
                        //disenio
                        theme={{
                            selectionColor: '#22639A',
                            textColor: {
                                default: '#333',
                                active: '#FFF'
                            },
                            weekdayColor: '#19B5CC',
                            headerColor: '#19B5CC',
                            floatingNav: {
                                background: '#22639A',
                                color: '#FFF',
                                chevron: '#22639A'
                            }
                        }}
                        //fin de disenio

                        //accion al seleccionar dia
                        onSelect={date => {seleccionarVallarta(date)}}
                        //fin de accion al seleccionar dia
                    />
                    <p className="titulo_1" style={{marginTop:"50px"}}>Calendario Guadalajara</p>
                    <InfiniteCalendar
                        //configuracion lenguaje
                        locale={{
                            weekStartsOn: 1,
                            weekdays: ["Dom","Lun","Mar","Mir","Jue","Vie","Sab"],
                            blank: 'Sin fecha seleccionada',
                            todayLabel: {
                                long: 'Hoy dia\'hoy',
                                short: 'Hoy'
                            }
                        }}
                        //fin configuracion lenguaje
                        //tamanio
                        width={"100%"}
                        height={window.innerHeight - 250}
                        rowHeight={70}
                        //fin tamanio
                        //disenio
                        theme={{
                            selectionColor: '#22639A',
                            textColor: {
                                default: '#333',
                                active: '#FFF'
                            },
                            weekdayColor: '#19B5CC',
                            headerColor: '#19B5CC',
                            floatingNav: {
                                background: '#22639A',
                                color: '#FFF',
                                chevron: '#22639A'
                            }
                        }}
                        //fin de disenio
                        //selector de fechas
                        Component={withMultipleDates(Calendar)}
                        selected={state.diasGuadalajara}
                        interpolateSelection={defaultMultipleDateInterpolation}
                        //fin selector de fechas

                        //accion al seleccionar dia
                        onSelect={date => {seleccionarGuadalajara(date)}}
                        //fin de accion al seleccionar dia
                    />
                </div>
            </Fragment>
        );
    }
}

Calendario.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'horarios',
            doc: "diasBloqueados"
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        horarios: ordered.horarios && ordered.horarios[0]
    }))
)(Calendario);