import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';


class Testimoniales extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            horas:['0:00', '0:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30']
        }
    }

    componentDidUpdate = () => {
        console.log(this.props);
    }


    render(){
        if (!this.props.citas) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="CITAS" />
                    <div className="container">
                        <h1>Guadalajara</h1>
                        <Table striped bordered hover style={{marginBottom:'50px'}}>
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                    <th>Nombre</th>
                                    <th>Telefono</th>
                                </tr>
                            </thead>
                            <tbody id="font">
                                {this.props.citas.map(dato => (
                                    dato.lugar === "guadalajara" || dato.lugar === "Guadalajara" ? (
                                        <tr key={dato.id}>
                                            <td>{dato.dia}</td>
                                            <td>{this.state.horas[dato.hora]}</td>
                                            <td>{dato.nombre} {dato.apellido}</td>
                                            <td>{dato.telefono}</td>
                                            <td>
                                                <a style={{marginLeft:'14%'}} href={`/admin/cita/${dato.id}`}><i class="fas fa-eye"></i></a>
                                            </td>
                                        </tr>
                                    ) : (
                                        <span></span>
                                    )
                                ))}
                            </tbody>
                        </Table>

                        <h1>Vallarta</h1>
                        <Table striped bordered hover style={{marginBottom:'50px'}}>
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                    <th>Nombre</th>
                                    <th>Telefono</th>
                                </tr>
                            </thead>
                            <tbody id="font">
                                {this.props.citas.map(dato => (
                                    dato.lugar === "vallarta" || dato.lugar === "Vallarta" ? (
                                        <tr key={dato.id}>
                                            <td>{dato.dia}</td>
                                            <td>{this.state.horas[dato.hora]}</td>
                                            <td>{dato.nombre} {dato.apellido}</td>
                                            <td>{dato.telefono}</td>
                                            <td>
                                                <a style={{marginLeft:'14%'}} href={`/admin/cita/${dato.id}`}><i class="fas fa-eye"></i></a>
                                            </td>
                                        </tr>
                                    ) : (
                                        <span></span>
                                    )
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    
                </Fragment>
            );
        }
    }
}

Testimoniales.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'citas',
            where: [
                "dia", ">=", new Date().getFullYear() + "-" + new Date().getMonth() + "-" + new Date().getDate()
            ]
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        citas: ordered.citas
    }))
)(Testimoniales);