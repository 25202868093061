import React, {Fragment}  from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

// *** componentes ***  //
import NavBar from '../Components/Header';
import Footer from '../Components/Footer';
//import Redes from '../Components/Redes';


// *** vistas ***  //
import Index from '../Views/Index';
import Nosotros from '../Views/Nosotros';
import Servicios from '../Views/Servicios';
import Servicio from '../Views/Servicio';
import ContactoGdl from '../Views/ContactoGdl';
import ContactoVall from '../Views/ContactoVall';
import Testimoniales from '../Views/Testimoniales';
import FormularioCita from '../Views/FormularioCita';
import Galeria from '../Views/Galeria';
// agradecimientos
import AgradeciemientoGDL from '../Views/AgradecimintoGDL';
import AgradeciemientoVallarta from '../Views/AgradecimintoVallarta';


// *** administrador *** //
import Login from '../Views/administrador/Login';
//Usuarios
import Usuarios from '../Views/administrador/Usuarios/Usuarios';
import NuevoUsuario from '../Views/administrador/Usuarios/NuevoUsuario';
import EditarUsuario from '../Views/administrador/Usuarios/EditarUsuario';
//servicios
import ServiciosAdmin from '../Views/administrador/Servicios/Servicios';
import NuevoServicio from '../Views/administrador/Servicios/NuevoServicio';
import EditarServicio from '../Views/administrador/Servicios/EditarServicio';
//testimoniales
import TestimonialesAdmin from '../Views/administrador/Testimoniales/Testimoniales';
import NuevoTestimonial from '../Views/administrador/Testimoniales/NuevoTestimonial';
import EditarTestimonial from '../Views/administrador/Testimoniales/EditarTestimonial';
//imagenes
import Imagenes from '../Views/administrador/Imagenes/Imagenes';
import NuevaImagenProducto from '../Views/administrador/Imagenes/NuevaImagen';
import EditarImagenProducto from '../Views/administrador/Imagenes/EditarImagen';
//galeria
import NuevaImagenGaleria from '../Views/administrador/Imagenes/Galeria/NuevaImagenGaleria';
//horarios
import Horario from '../Views/administrador/Horarios/Horarios';
//citas
import Citas from '../Views/administrador/Citas/Citas';
import Cita from '../Views/administrador/Citas/Cita';
//dias
import Calendario from '../Views/administrador/Calendario/Calendario';


// Error
import Error from '../Views/Error';


const Router = () => (
    <BrowserRouter>
        {window.location.pathname.split('/')[1] === 'admin' ? (
            <span></span>
        ) : (
            <NavBar />
        )}
        <Routes>
            {/* vistas */}
            <Route exact path="/" element={<Index />} />
            <Route path="/dra-paola-jacobo" element={<Nosotros />} />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/servicios/:id" element={<Servicio />} />
            <Route path="/contacto-guadalajara" element={<ContactoGdl />} />
            <Route path="/contacto-vallarta" element={<ContactoVall />} />
            <Route path="/testimoniales" element={<Testimoniales />} />
            <Route path="/galeria" element={<Galeria />} />
            <Route path="/agenda-una-cita/:datos" element={<FormularioCita />} />
            <Route path="/agradecimiento-guadalajara" element={<AgradeciemientoGDL />} />
            <Route path="/agradecimiento-vallarta" element={<AgradeciemientoVallarta />} />
            {/* fin - vistas */}
            {/* administrador */}
                <Route path="/admin/login" element={<Login />} />
                    {/* usuario */}
                    <Route path="/admin/usuarios" element={<Usuarios />} />
                    <Route path="/admin/nuevo-usuario" element={<NuevoUsuario />} />
                    <Route path="/admin/editar-usuario/:id" element={<EditarUsuario />} />
                    {/* fin - usuario */}
                    {/* servicios */}
                    <Route path="/admin/servicios" element={<ServiciosAdmin />} />
                    <Route path="/admin/nuevo-servicio" element={<NuevoServicio />} />
                    <Route path="/admin/editar-servicio/:id" element={<EditarServicio />} />
                    {/* fin - servicios */}
                    {/* testimoniales */}
                    <Route path="/admin/testimoniales" element={<TestimonialesAdmin />} />
                    <Route path="/admin/nuevo-testimonial" element={<NuevoTestimonial />} />
                    <Route path="/admin/editar-testimonial/:id" element={<EditarTestimonial />} />
                    {/* fin - testimoniales */}
                    {/* imagenes */}
                    <Route path="/admin/imagenes" element={<Imagenes />} />
                    <Route path="/admin/nueva-imagen-servicio/:id" element={<NuevaImagenProducto />} />
                    <Route path="/admin/editar-imagen-servicio/:id" element={<EditarImagenProducto />} />
                    {/* fin - imagenes */}
                    {/* galeria */}
                    <Route path="/admin/nueva-imagen-galeria" element={<NuevaImagenGaleria />} />
                    {/* fin - galeria */}
                    {/* horarios */}
                    <Route path="/admin/horarios" element={<Horario />} />
                    {/* fin - horarios */}
                    {/* citas */}
                    <Route path="/admin/citas" element={<Citas />} />
                    <Route path="/admin/cita/:id" element={<Cita />} />
                    {/* fin - citas */}
                    {/* calendario */}
                    <Route path="/admin/calendario" element={<Calendario />} />
                    {/* fin - calendario */}
                {/* fin - administrador */}
            <Route path="*" element={<Error />} />
        </Routes>
        {window.location.pathname.split('/')[1] === 'admin' ? (
            <span></span>
        ) : (
            <Footer />
        )}
    </BrowserRouter>
);

export default Router;