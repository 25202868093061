import React, {Fragment, Component}  from 'react';
import ReactDOM from 'react-dom';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../../Components/SideNav';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

class NuevaImagenGaleria extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            file1: '',
            picture: null,
            descripcion_esp: '',
            descripcion_ing: '',
            prueba: ''
        }
    }

    handelUpload = e => {
        e.preventDefault();

        this.setState({
            file1: e.target.files[0]
        }, () => {
            this.precarga();
        });
    }

    precarga = () => {
        var file = this.state.file1,
        imageType = /image.*/;
        
        if (!file.type.match(imageType))
        return;
    
        var reader = new FileReader();
    
        reader.onload = function(e){
            var result = e.target.result;
            document.getElementById("imgSalida").src = result;
        }
        
        reader.readAsDataURL(file);
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    subirDB = async file => {
        const { firestore } = this.props;

        if (file === '1') {
            if ((this.state.file1 === '') || (this.state.descripcion_esp === '') || (this.state.descripcion_ing === '')) {
                Swal.fire(
                    'Error',
                    'No hay archivo seleccionado <br/>o <br/> Error en el descripcion',
                    'error'
                )
            } else {
                this.preloader();
                const { firebase } = this.props;
                var file1 = this.state.file1;


                const storage = getStorage();
                const storageRef = ref(storage, `/images/galeria/${Math.random().toString(36).substr(2)}`);

                const uploadTask = uploadBytesResumable(storageRef, file1);

                // Register three observers:
                // 1. 'state_changed' observer, called any time the state changes
                // 2. Error observer, called on failure
                // 3. Completion observer, called on successful completion
                uploadTask.on('state_changed', 
                    (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 800;

                        this.setState({
                            uploadValue: progress
                        });
                    }, 
                    (error) => {
                        // Handle unsuccessful uploads
                        Swal.fire(
                            'Error',
                            'Error al subir el archivo',
                            'error'
                        )
                    }, 
                    () => {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // console.log('File available at', downloadURL);
                            //guardado en la base de datos

                            const galeria = {
                                path: downloadURL,
                                descripcion_esp: this.state.descripcion_esp,
                                descripcion_ing: this.state.descripcion_ing
                            }
                            //guardado en la base de datos
                            firestore.add({
                                collection: 'galeria'
                            }, galeria ).then(() => this.editado());
                        });
                    }
                );
            }
        }
    }

    editado = () => {
        Swal.fire(
            'Inserción exitosa',
            'Imagen insertada correctamente',
            'success'
        )
        window.location.replace("/admin/imagenes");
    }

    preloader = () => {
        var preloader = 
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div><div class="gap-patch">
                    <div class="circle"></div>
                </div><div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>;
        ReactDOM.render(preloader, document.getElementById('preloader'));
    }

    render(){
        return (
            <Fragment>
                <Header mensaje="NUEVA IMAGEN GALERIA" />
                <div className="container center-align" style={{marginTop:'50px'}}>
                    <div class="progress">
                        <div class="determinate" style={{width:this.state.uploadValue}}></div>
                    </div>
                    <div id="preloader" style={{display:"flex", justifyContent:"center", marginBottom:'30px', justifyItems:"center"}}>
                        
                    </div>
                    <div className="row">
                        <div className="col s4">    
                            <div className="file-field input-field col s12">
                                <div className="btn">
                                    <span>File</span>
                                    <input onChange={this.handelUpload} name="file" type="file"/>
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path validate" type="text" placeholder="Seleccione una foto o arrastre una foto"/>
                                </div>
                            </div>
                            <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="descripcion_esp" id="descripcion_esp" type="text" class="validate" />
                                    <label for="descripcion_esp">Descripcion de la imagen español</label>
                            </div>
                            <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="descripcion_ing" id="descripcion_ing" type="text" class="validate" />
                                    <label for="descripcion_ing">Descripcion de la imagen ingles</label>
                            </div>
                            <div className=" btn-outline-light center col s12" style={{padding:'30px 0', display:"flex", flexWrap:"wrap" ,justifyContent:"center", alignItems:"center"}}>
                                <div id="font" class="btn waves-effect waves-light" onClick={() => this.subirDB('1')} style={{padding:'0 50px', background:'#235677', margin:"10px"}}>AGREGAR</div>
                                <Link to={`/admin/imagenes`} id="font" class="btn waves-effect waves-light" style={{padding:'0 50px', background:'#235677', margin:"10px"}}>REGRESAR</Link>
                            </div>
                        </div>
                        <div className="col s8">
                            <img alt="imagen subida" id="imgSalida" width="100%" src=""></img>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

NuevaImagenGaleria.protoType = {
    firestor : PropTypes.object.isRequired
}

export default firestoreConnect()(NuevaImagenGaleria);