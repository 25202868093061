import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import firebaseHelper from '../../../Helpers/Firebase';


class EditarTestimonial extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nombre: ""
        }
    }

    editar = e => {
        const { history } = this.props;
        e.preventDefault();
    
        //extraer firestor de props
        const { firestore } = this.props;

        var editar = {...this.state}

        //actualizacion en la base de datos
        firebaseHelper.editar(this.props.testimoniales.id, 'testimoniales', firestore, editar, `/admin/testimoniales`, history);

    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidUpdate = () => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        if (this.state.nombre === '') {
            this.setState({
                nombre: this.props.testimoniales.nombre,
                testimonio_esp: this.props.testimoniales.testimonio_esp,
                testimonio_ing: this.props.testimoniales.testimonio_ing

            });
        }
    }

    render(){
        if (!this.props.testimoniales) {
            return(
                <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-red darken-4-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="EDITAR TESTIMONIAL" />
                    <div id="font-text" className="container center">
                        <div style={{ margin:'100px 15% 100px 15%'}}>
                            <form onSubmit={this.editar}>
                                <div>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} defaultValue={this.state.nombre} name="nombre" id="nombre" type="text" class="validate" />
                                        <label className="active" for="nombre">Nombre</label>
                                    </div>
                                </div>
                                <div>
                                    <h3>Español</h3>
                                    <div class="input-field col s12">
                                        <textarea required onChange={this.leerDato} defaultValue={this.state.testimonio_esp} name="testimonio_esp" id="testimonio_esp" class="materialize-textarea"></textarea>
                                        <label className="active" for="testimonio_esp">Textarea</label>
                                    </div>
                                </div>

                                <div style={{marginTop:"50px"}}>
                                    <h3>Ingles</h3>
                                    <div class="input-field col s12">
                                        <textarea required onChange={this.leerDato} defaultValue={this.state.testimonio_ing} name="testimonio_ing" id="testimonio_ing" class="materialize-textarea"></textarea>
                                        <label className="active" for="testimonio_ing">Textarea</label>
                                    </div>
                                </div>

                                <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#235677 '}}>EDITAR</button>
                                <Link to={`/admin/testimoniales`} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#235677 '}}>REGRESAR</Link>
                            </form>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}

EditarTestimonial.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'testimoniales',
            doc: window.location.pathname.split('/')[3]
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        testimoniales: ordered.testimoniales && ordered.testimoniales[0]
    }))
)(EditarTestimonial)