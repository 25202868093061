import React, {Component} from 'react';
import M from 'materialize-css';
import { FormattedMessage } from 'react-intl';

import face from "../Images/icons/face.png";
import whats from "../Images/icons/whats.png";
import insta from "../Images/icons/insta.png";

class Footer extends Component {
    constructor (props) {
        super(props);
            this.state = {
        }
    }

    componentDidMount = () => {
        var elems = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elems); 
    }

    render (){
        return [
            <footer class="page-footer fondo_3">
                <div class="container fondo_div_3">
                    <div class="row" style={{marginBottom:"0px"}}>
                        <div className="col s12 m12 l4">
                            <p style={{fontSize:"25px", height:"111px"}}>Guadalajara, Jalisco</p>
                            <a target="blanck" href="https://goo.gl/maps/H3ufhdrHh89K5J7bA">
                                <div className="padre_3" style={{color:"white"}}>
                                    <i class="fas fa-map-marker-alt" style={{fontSize:"50px", marginRight:"20px"}}></i>
                                    <p>PUNTO MÉDICO <br />GUADALUPE, Avenida <br />Guadalupe 4083-B.</p>
                                </div>
                            </a>
                            <div className="padre_3">
                                <i class="fas fa-phone-alt" style={{fontSize:"50px", marginRight:"20px"}}></i>
                                <p><a target="blanck" href="tel:3331228967" style={{color:"white"}}>(33) 31228967</a> <br /><a target="blanck" href="tel:3317132941" style={{color:"white"}}>(33) 17132941</a></p>
                            </div>
                            <div className="padre_3">
                                <i class="far fa-envelope" style={{fontSize:"50px", marginRight:"20px"}}></i>
                                <a target="blanck" href="mailto:drajacobo@oftalretina.com?Subject=¡Hola!&body=¡Hola%20buen%20día!%20Me%20podira%20brindar%20información%20acerca%20de%20de%20sus%20servicios."><p style={{color:"white"}}>drajacobo@oftalretina.com</p></a>
                            </div>
                        </div>
                        <div className="col s12 m12 l4">
                            <div className="padre_3" style={{justifyContent:"center", height:"164px"}}>
                                <div className="imagenes_footer">
                                    <a target="blanck" href="https://www.facebook.com/Dra-Paola-Jacobo-Oceguera-125158903079603"><img src={face} alt="" style={{width:"35px"}} /></a>
                                </div>
                                <div className="imagenes_footer">
                                    <a target="blanck" href="https://www.instagram.com/dra.paola_jacobo_oceguera/"><img src={insta} alt="" style={{width:"35px"}} /></a>
                                </div>
                                <div className="imagenes_footer">
                                    <a target="blanck" href="https://wa.me/523320263649?text=¡Hola buen día! ¿Me puede brindar información de sus servicios?"><img src={whats} alt="" style={{width:"35px"}} /></a>
                                </div>
                            </div>
                            <p><i class="fas fa-square" style={{fontSize:"10px", position:"relative", bottom:"3px"}}></i> <a target="blanck" href="/dra-paola-jacobo" style={{color:"white"}}>Dra. Paola Jacobo</a></p>
                            <p><i class="fas fa-square" style={{fontSize:"10px", position:"relative", bottom:"3px"}}></i>
                                <a href="/servicios" style={{color:"white"}}>
                                    <FormattedMessage
                                        id="header.1"
                                        defaultMessage="Servicios"
                                    /> 
                                </a>
                            </p>
                            <p><i class="fas fa-square" style={{fontSize:"10px", position:"relative", bottom:"3px"}}></i>
                                <a href="galeria" style={{color:"white"}}>
                                    <FormattedMessage
                                        id="header.2"
                                        defaultMessage="Galeria"
                                    />
                                </a>
                            </p>
                            <p><i class="fas fa-square" style={{fontSize:"10px", position:"relative", bottom:"3px"}}></i>
                                <a href="/testimoniales" style={{color:"white"}}>
                                    <FormattedMessage
                                        id="header.3"
                                        defaultMessage="Testimoniales"
                                    />
                                </a>
                            </p>
                        </div>
                        <div className="col s12 m12 l4">
                            <p style={{fontSize:"25px"}}>Nuevo Vallarta, <br />Bahía de Banderas, <br />Nayarit.</p>
                            <a target="blanck" href="https://g.page/hospitaljoyariviera?share">
                                <div className="padre_3" style={{color:"white"}}>
                                    <i class="fas fa-map-marker-alt" style={{fontSize:"50px", marginRight:"20px"}}></i>
                                    <p>Hospital Joya Riviera  <br />Nayarit, Paseo de los <br />Cocoteros #55</p>
                                </div>
                            </a>
                            <div className="padre_3">
                                <i class="fas fa-phone-alt" style={{fontSize:"50px", marginRight:"20px"}}></i>
                                <p><a target="blanck" href="tel:3223490468" style={{color:"white"}}>(322) 3490468</a> <br /><a target="blanck" href="tel:3226887701" style={{color:"white"}}>(322) 6887701</a></p>
                            </div>
                            <div className="padre_3">
                                <i class="far fa-envelope" style={{fontSize:"50px", marginRight:"20px"}}></i>
                                <a target="blanck" href="mailto:drajacobo@oftalretina.com?Subject=¡Hola!&body=¡Hola%20buen%20día!%20Me%20podira%20brindar%20información%20acerca%20de%20de%20sus%20servicios."><p style={{color:"white"}}>drajacobo@oftalretina.com</p></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-copyright">
                    <div class="container center">
                        © {new Date().getFullYear()} Copyright Oftalretina
                    </div>
                </div>
            </footer>
        ]
    }
}

export default Footer;