import React, {Fragment, Component}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import firebaseHelper from '../../../Helpers/Firebase';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


class NuevoProducto extends Component {
    
    constructor(props){
        super(props)
        this.state = {}
    }

    componentDidUpdate = () => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }

    agregar = e => {
        e.preventDefault();
    
        //extraer firestor de props
        const { firestore } = this.props;

        //guardado en la base de datos
        var nuevo = {...this.state}

        //console.log(nuevo);
        firebaseHelper.agregar('servicios', firestore, nuevo, `/admin/servicios`);
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    informacion_esp = (e, editor) => {
        this.setState({
            informacion_esp: editor.getData()
        })
    }

    informacion_ing = (e, editor) => {
        this.setState({
            informacion_ing: editor.getData()
        })
    }


    render(){
        return (
            <Fragment>
                <Header mensaje="NUEVO SERVICIO" />
                <div id="font" className="container">    
                    <div className="center" style={{padding: '30px 0 30px 0'}}>
                        <form onSubmit={this.agregar}>
                            <div>
                                <h3>Español</h3>
                                <div style={{margin:'0 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} name="nombre_esp" id="nombre_esp" type="text" class="validate" />
                                        <label for="nombre_esp">Titulo</label>
                                    </div>
                                </div>
                                <div className="container" style={{textAlign:"left"}}>
                                    <h5>Información</h5>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        onInit={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={this.informacion_esp}
                                    />
                                </div>
                            </div>

                            <div style={{marginTop:"100px"}}>
                                <h3>Ingles</h3>
                                <div style={{margin:'0 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} name="nombre_ing" id="nombre_ing" type="text" class="validate" />
                                        <label for="nombre_ing">Titulo</label>
                                    </div>
                                </div>
                                <div className="container" style={{textAlign:"left"}}>
                                    <h5>Información</h5>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        onInit={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={this.informacion_ing}
                                    />
                                </div>
                            </div>

                            <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#235677 ', marginTop:'25px'}}>AGREGAR</button>
                            <Link to={`/admin/servicios`} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#235677 ', marginTop:'25px'}}>REGRESAR</Link>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
}

NuevoProducto.protoType = {
    firestor : PropTypes.object.isRequired
}

export default firestoreConnect()(NuevoProducto);