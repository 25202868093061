import React, {Component, Fragment}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { FormattedMessage } from 'react-intl';
import encriptar from  '../Helpers/Encriptar';
import CryptoJS from "react-native-crypto-js";

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import apiHelper from "../Helpers/API";


class FormularioCita extends Component {

    constructor(props){
        super(props);
        this.state = {
            cita: "Para mí",
            pais: "52"
        }
    }

    componentDidMount = () => {
        const { history } = this.props;
        const dato = encriptar.decode(window.location.pathname.split("/")[2]);
        let bytes  = CryptoJS.AES.decrypt(dato, 'Y2Fhdg==');
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        const array_datos = originalText.split("_");

        if (originalText && array_datos.length === 3) {
            this.setState({
                dia: array_datos[0],
                hora: array_datos[1],
                lugar: array_datos[2]
            });
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Error en la fecha seleccionada',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon: 'error'
            }).then(res => {
                if (res.isDismissed || res.isConfirmed) {
                    history.push("/")
                }
            })
        }
    }

    cita = (e, cita) => {
        e.preventDefault();
        document.getElementsByClassName("activo")[0].className = "formulario_opcion";
        e.target.className = "formulario_opcion activo";
        this.setState({
            cita: cita
        });
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    leerNumero = (value, selectedCountryData, fullNumber, extension) => {
        // console.log({value, selectedCountryData, fullNumber, extension});
        this.setState({
            telefono: value
        })
    }

    leerPais = (currentNumber, selectedCountryData, fullNumber) => {
        // console.log({currentNumber, selectedCountryData, fullNumber});
        this.setState({
            pais: selectedCountryData.dialCode
        })
    }

    salirNotificacion = () => {
        document.getElementById("notificacion").className = "invisible";
        window.location.replace("/");
    }

    salirNotificacionOcupada = () => {
        document.getElementById("notificacionOcupado").className = "invisible";
        window.location.replace("/contacto");
    }

    agendar = e => {
        e.preventDefault();
        const horas = ['0:00', '0:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];

        const index =  horas.findIndex((dato, index) => dato === this.state.hora)

        if (index === -1) {
            Swal.fire({
                title: 'Error',
                text: 'Error en la fecha seleccionada',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon: 'error'
            })
        } else {
            if (!this.state.telefono || this.state.telefono.length !== 10) {
                return Swal.fire({
                    title: 'Error',
                    text: 'El telefono debe tener 10 digitos',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }

            this.setState({
                hora: index
            }, () => {
                this.agendarCalendar();
            });
        }
    }

    agendarCalendar = async () => {
        const { history } = this.props;

        document.getElementById("cargando").className = "visible";
        const mesEsp = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        const mesIng = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const horas = ['0:00', '0:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];
        
        //extraer valores del state
        const { firestore } = this.props;
        const nuevo = {...this.state};

        // generamos los datos de las fechas
        const segundos = ['0'];
        let dia = nuevo.dia.split("-");
        let horaInicio = horas[nuevo.hora].split(":");
        let fechaInicio = dia.concat(horaInicio, segundos);
        let fechaInicioFin = [];
        let horaFin = horas[nuevo.hora + 1].split(":");
        let fechaFin = dia.concat(horaFin, segundos);
        let fechaFinFin = [];

        let mensaje;
        let lugar;


        fechaInicio.map(dato => {
            fechaInicioFin.push(parseInt(dato));
        });

        fechaFin.map(dato => {
            fechaFinFin.push(parseInt(dato));
        });

        if (this.state.lugar === "Vallarta") {
            lugar = "Hospital Joya Riviera Nayarit"
        } else {
            lugar = "Nucleo Médico Guadalupe"
        }

        if (localStorage.getItem('_lang_dra_po') === "en-US") {
            mensaje = "Hello " + this.state.apellido.toUpperCase() + " " + this.state.nombre.toUpperCase() + 
                " \nHis appointment has been successfully scheduled on:\n" + dia[2] + ", " + mesIng[dia[1]] + ", " + dia[0] + 
                "\nfrom " + horas[this.state.hora] + " to " + horas[this.state.hora + 1] + " at the " + lugar + ".\nDra. Paola Jacobo\n\nDo not reply to this message"
        } else {
            mensaje = "Saludos " + this.state.apellido.toUpperCase() + " " + this.state.nombre.toUpperCase() + 
                " \nSu cita se ha agendado exitosamente el dia:\n" + dia[2] + ", " + mesEsp[dia[1]] + ", " + dia[0] + 
                "\nde " + horas[this.state.hora] + " a " + horas[this.state.hora + 1] + " en el " + lugar + ".\nDra. Paola Jacobo\n\nNo responda a este mensaje"
        }


        let dataCalendar = {
            "fechaInicio": fechaInicioFin,
            "fechaFinal": fechaFinFin,
            "descripcion": "Cita para " + this.state.apellido.toUpperCase() + " " + this.state.nombre.toUpperCase() + " en el consultorio de " + this.state.lugar,
            "titulo": this.state.apellido.toUpperCase() + " " + this.state.nombre.toUpperCase(),
            "ubicacion": lugar
        }

        let dataSMS = {
            "numero": this.state.pais + this.state.telefono,
            "mensaje": mensaje
        }

        console.log(dataSMS);

        try {
            let resCalendar = await apiHelper.agregarEvento(dataCalendar);

            if (resCalendar.success) {
                let resSMS = await apiHelper.enviarSMS(dataSMS);
                console.log({resCalendar, resSMS});
    
                document.getElementById("cargando").className = "invisible";
                firestore.collection("citas").doc(this.state.dia + "-" + this.state.hora + "-" + Math.random().toString(36).slice(-10)).set(nuevo).then((res) => {
                    document.getElementById("notificacion").className = "visible";
                });
            } else {
                document.getElementById("cargando").className = "invisible";
                document.getElementById("notificacionOcupado").className = "visible";
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Ocurrio un error inesperado, por favor contactenos',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon: 'error'
            }).then(() => {
                history.push("/")
            })
        }

    }

    render (){
        if (!this.props.horarios) {
            return (
                <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-red darken-4-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <Fragment>
                    <div id="cargando" className="invisible" style={{position:"fixed", width:"100%", height:"100vh", zIndex:"9999", background:"#00000062", top:"0", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-blue-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div><div class="gap-patch">
                                    <div class="circle"></div>
                                </div><div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="notificacion" className="invisible" onClick={this.salirNotificacion} style={{position:"fixed", zIndex:"9999", top:"0%", width:"100%", display:"flex", justifyContent:"center", alignItems:"center", height:"1080px"}}>
                        <div id="notificacion_hijo" className="notificacion">
                            <div style={{display:"flex", justifyContent:"flex-end", position:"relative", top:"-35px"}}>
                                <i class="fas fa-times" style={{position:"relative", top:"10px", right:"30px"}} onClick={this.salirNotificacion}></i>
                            </div>
                            <p>
                                <FormattedMessage
                                    id="enviado.1"
                                    defaultMessage="¡Cita reservada!"
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id="enviado.2"
                                    defaultMessage="Gracias por reservar con nosotros"
                                />
                            </p>
                        </div>
                    </div>
                    <div id="notificacionOcupado" className="invisible" onClick={this.salirNotificacionOcupada} style={{position:"fixed", zIndex:"9999", top:"0%", width:"100%", display:"flex", justifyContent:"center", alignItems:"center", height:"1080px"}}>
                        <div id="notificacion_hijo" className="notificacion">
                            <div style={{display:"flex", justifyContent:"flex-end", position:"relative", top:"-35px"}}>
                                <i class="fas fa-times" style={{position:"relative", top:"10px", right:"30px"}} onClick={this.salirNotificacionOcupada}></i>
                            </div>
                            <p>
                                <FormattedMessage
                                    id="enviado.3"
                                    defaultMessage="Cita no disponible"
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id="enviado.4"
                                    defaultMessage="Contactanos por telefon."
                                />
                            </p>
                        </div>
                    </div>
                    <div className="container">
                        <p className="titulo_2">
                            <FormattedMessage
                                id="formulario.1"
                                defaultMessage="Reserva de cita"
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="formulario.2"
                                defaultMessage="¿Para quién es la cita?"
                            />
                        </p>
                        <div style={{display:"flex", borderRadius:"10px"}}>
                            <div onClick={e => {this.cita(e, "Para mí")}} className="formulario_opcion activo">
                                <FormattedMessage
                                    id="formulario.3"
                                    defaultMessage="Para mí"
                                />
                            </div>
                            <div onClick={e => {this.cita(e, "Otra persona")}} className="formulario_opcion">
                                <FormattedMessage
                                    id="formulario.4"
                                    defaultMessage="Otra persona"
                                />
                            </div>
                        </div>
                        <p>
                            <FormattedMessage
                                id="formulario.5"
                                defaultMessage="Información personal"
                            />
                        </p>
                        <form onSubmit={this.agendar}>
                            
                            <div className="row">
                                <div class="input-field col s12">
                                    <input onChange={this.leerDato} name="nombre" id="nombre" type="text" className="validate formulario" required />
                                    <label for="nombre" style={{paddingLeft:"15px"}}>
                                        <FormattedMessage
                                            id="formulario.6"
                                            defaultMessage="Nombre(s)"
                                        />
                                    </label>
                                </div>
                                <div class="input-field col s12">
                                    <input onChange={this.leerDato} name="apellido" id="apellido" type="text" className="validate formulario" required />
                                    <label for="apellido" style={{paddingLeft:"15px"}}>
                                        <FormattedMessage
                                            id="formulario.7"
                                            defaultMessage="Apellidos"
                                        />
                                    </label>
                                </div>
                            </div>
                            <p>
                                <FormattedMessage
                                    id="formulario.8"
                                    defaultMessage="Información de contacto"
                                />
                            </p>
                            <div className="row">
                                <div class="input-field col s12">
                                    <input onChange={this.leerDato} name="email" id="email" type="email" className="validate formulario" required />
                                    <label for="email" style={{paddingLeft:"15px"}}>
                                        Email
                                    </label>
                                </div>
                                <div class="input-field col s12">
                                    <IntlTelInput
                                        ClassName="intl-tel-input"
                                        inputClassName="form-control"
                                        className="validate formulario"
                                        name="telefono"
                                        id="telefono"
                                        pattern="[0-9]{13}"
                                        preferredCountries={["mx","us"]}
                                        onlyCountries={["mx","us"]}
                                        onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber, extension) => {this.leerNumero(value, selectedCountryData, fullNumber, extension)}}
                                        onSelectFlag={(currentNumber, selectedCountryData, fullNumber) => {this.leerPais(currentNumber, selectedCountryData, fullNumber)}}
                                    />
                                    <label for="telefono" className="active" style={{paddingLeft:"15px"}}>
                                        <FormattedMessage
                                            id="formulario.9"
                                            defaultMessage="Numero de celular"
                                        />
                                    </label>
                                </div>
                            </div>
                            <p>
                                <FormattedMessage
                                    id="formulario.10"
                                    defaultMessage="¿Tienes fiebre, tos, falta de aire o dificultad para respirar, o has estado en contacto con alguien que pueda presentar alguno de estos síntomas?"
                                />
                            </p>
                            <div style={{display:"flex"}}>
                                <p style={{width:"100px"}}>
                                    <label>
                                        <input name="enfermedad" value="Si" onChange={this.leerDato} type="radio" required />
                                        <span>
                                            <FormattedMessage
                                                id="formulario.11"
                                                defaultMessage="Si"
                                            />
                                        </span>
                                    </label>
                                </p>
                                <p style={{width:"100px"}}>
                                    <label>
                                        <input name="enfermedad" value="No" onChange={this.leerDato} type="radio" required />
                                        <span>
                                            <FormattedMessage
                                                id="formulario.12"
                                                defaultMessage="No"
                                            />
                                        </span>
                                    </label>
                                </p>
                            </div>
                            <p>
                                <FormattedMessage
                                    id="formulario.13"
                                    defaultMessage="¿Es tu primera visita con este especialista?"
                                />
                            </p>
                            <div style={{display:"flex"}}>
                                <p style={{width:"100px"}}>
                                    <label>
                                        <input name="visita" value="Si" onChange={this.leerDato} type="radio" required />
                                        <span>
                                            <FormattedMessage
                                                id="formulario.11"
                                                defaultMessage="Si"
                                            />
                                        </span>
                                    </label>
                                </p>
                                <p style={{width:"100px"}}>
                                    <label>
                                        <input name="visita" value="No" onChange={this.leerDato} type="radio" required />
                                        <span>
                                            <FormattedMessage
                                                id="formulario.12"
                                                defaultMessage="No"
                                            />
                                        </span>
                                    </label>
                                </p>
                            </div>
                            <p>
                                <FormattedMessage
                                    id="formulario.14"
                                    defaultMessage="Comentario para el especialista (opcional)"
                                />
                            </p>
                            <div class="row">
                                <div class="input-field col s12">
                                    <textarea name="comentario" id="textarea"  onChange={this.leerDato} className="materialize-textarea formulario"></textarea>
                                    <label for="textarea" style={{paddingLeft:"15px"}}>
                                        <FormattedMessage
                                            id="formulario.15"
                                            defaultMessage="Puedes añadir cualquier comentario que sea de interés para el especialista"
                                        />
                                    </label>
                                </div>
                            </div>
                            <div style={{height:"100px"}}>
                                <button type="submit" className="boton_2" style={{width:"100%", textAlign:"center"}}>
                                    <FormattedMessage
                                        id="formulario.16"
                                        defaultMessage="Agendar"
                                    />
                                </button>
                                
                                {/* <div onClick={this.agendar} className="boton_2" style={{width:"100%", textAlign:"center"}}>
                                    <FormattedMessage
                                        id="formulario.16"
                                        defaultMessage="Agendar"
                                    />
                                </div> */}
                            </div>
                        </form>
                    </div>
                </Fragment>
            )
        }
    }
}

FormularioCita.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'horarios',
            doc: "2rFyHG8As9UCaARJlpVX"
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        horarios: ordered.horarios && ordered.horarios[0]
    }))
)(FormularioCita)