import React, {useContext}  from 'react';
import { langContext } from '../Context/langContext';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import ReactHtmlParser from 'react-html-parser';

const Servicio = props => {
    const idioma = useContext(langContext);

    if (!props.servicios) {
        return (
            <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                <div class="preloader-wrapper big active">
                    <div class="spinner-layer spinner-red darken-4-only">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="gap-patch">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="container padre" style={{marginBottom:"50px"}}>
                <div className="hijo_5 contenedor_galeria" style={{justifyContent:"center", alignItems:"center"}}>
                    <img src={props.servicios.path} alt="" style={{width:"100%"}} />
                </div>
                <div className="hijo_6 contenedor_galeria valign-wrapper" style={{flexDirection:"column", paddingLeft:"20px", justifyContent:"center", alignItems:"center"}}>
                    {idioma.obtenerLenguaje() === "es-MX" ? (
                        <p className="titulo_1" style={{textAlign:"left", marginTop:"0px"}}><span>{ReactHtmlParser(props.servicios.nombre_esp)}</span></p>
                    ) : (
                        <p className="titulo_1" style={{textAlign:"left", marginTop:"0px"}}><span>{ReactHtmlParser(props.servicios.nombre_ing)}</span></p>
                    )}
                    {idioma.obtenerLenguaje() === "es-MX" ? (
                        <p>{ReactHtmlParser(props.servicios.informacion_esp)}</p>
                    ) : (
                        <p>{ReactHtmlParser(props.servicios.informacion_ing)}</p>
                    )}
                </div>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"140px", width:"100%", flexDirection:"column", gap:"10px"}}>
                    <a href="/contacto-guadalajara" className="boton" style={{width:"60%", textAlign:"center", height:"70px", border:"none"}}>
                        <FormattedMessage
                            id="servicios.2"
                            defaultMessage="Agenda una cita"
                        />{" Guadalajara"}
                    </a>

                    <a href="/contacto-vallarta" className="boton" style={{width:"60%", textAlign:"center", height:"70px", border:"none"}}>
                        <FormattedMessage
                            id="servicios.2"
                            defaultMessage="Agenda una cita"
                        />{" Nv. Vallarta"}
                    </a>
                </div>
            </div>
        );
    }
}
 
Servicio.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'servicios',
            doc: window.location.pathname.split("/")[2]
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        servicios: ordered.servicios && ordered.servicios[0]
    }))
)(Servicio);