import React, { useEffect, useContext } from 'react';
import { langContext } from '../Context/langContext';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { FormattedMessage } from 'react-intl';

import M from 'materialize-css';

import icon1 from "../Images/icons/1.png";
import icon2 from "../Images/icons/2.png";
import icon3 from "../Images/icons/3.png";
import icon4 from "../Images/icons/4.png";
import icon5 from "../Images/icons/5.png";
import icon6 from "../Images/icons/6.png";
import icon7 from "../Images/icons/7.png";

import img2 from "../Images/2.png";
import img3 from "../Images/3.png";


const Index = props => {
    const idioma = useContext(langContext);

    useEffect(() => {
        var elems = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(elems);
    });

    if (!props.servicios) {
        return (
            <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                <div class="preloader-wrapper big active">
                    <div class="spinner-layer spinner-red darken-4-only">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="gap-patch">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return ( 
            <div>
                <div className="fondo_1"></div>
                <div className="padre sombras container" style={{position:"relative", top:"-100px", zIndex:"999"}}>
                    <div className="hijo_1 height_530" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <img src={img2} alt="" style={{width:"100%"}} />
                    </div>
                    <div className="hijo_1 padre_1">
                        <p className="titulo_1" style={{marginTop:"0px"}}>“
                            <FormattedMessage
                                id="index.1"
                                defaultMessage="Compromiso y pasión "
                            /> <br />
                            <FormattedMessage
                                id="index.1.2"
                                defaultMessage="por la "
                            />
                            <span className="grueso_1">
                                <FormattedMessage
                                    id="index.1.1"
                                    defaultMessage="oftalmología"
                                />”
                            </span>
                        </p>
                        <div className="padre" style={{marginBottom:"50px"}}>
                            <div className="padre_1 hijo_1_1" style={{padding:"0px 20px", textAlign:"center"}}>
                                <div className="sombras_3">
                                    <img src={icon1} alt="" style={{width:"100%"}} />
                                </div>
                                <p>
                                    <FormattedMessage
                                        id="index.2"
                                        defaultMessage="Atención integral personalizada a los distintos problemas 
                                        y enfermedades de los ojos"
                                    />
                                </p>
                            </div>
                            <div className="padre_1 hijo_1_1" style={{padding:"0px 20px", textAlign:"center"}}>
                                <div className="sombras_3">
                                    <img src={icon2} alt="" style={{width:"100%"}} />
                                </div>
                                <p>
                                    <FormattedMessage
                                        id="index.3"
                                        defaultMessage="Servicio de calidad desde exámenes de rutina hasta tratamientos 
                                        quirúrgicos altamente especializados y de clase mundial."
                                    />
                                </p>
                            </div>
                        </div>
                        <a href="/contacto-guadalajara" >
                            <div className="boton_1">
                                <p>
                                    <FormattedMessage
                                        id="index.4"
                                        defaultMessage="Agendar una cita"
                                    />{" Guadalajara"}
                                </p>
                            </div>
                        </a>
                        <a href="/contacto-vallarta" style={{marginTop:"10px"}}>
                            <div className="boton_1">
                                <p>
                                    <FormattedMessage
                                        id="index.4"
                                        defaultMessage="Agendar una cita"
                                    />{" Nv. Vallarta"}
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
    
                <div className="padre container">
                    <div className="hijo_2">
                        <img src={img3} alt="" style={{width:"100%"}} />
                        <div className="index_doctora">
                            <div className="padre_4 sombras_1">
                                <i style={{color:"#19B5CC", fontSize:"50px", paddingRight:"30px"}} class="fas fa-award"></i>
                                <div>
                                    <p style={{margin:"0px"}}>
                                        <FormattedMessage
                                            id="index.11"
                                            defaultMessage="Primer Lugar en"
                                        />
                                    </p>
                                    <p style={{margin:"0px"}}>
                                        <FormattedMessage
                                            id="index.12"
                                            defaultMessage="La Certificación por parte del "
                                        />
                                        <span>
                                            <FormattedMessage
                                                id="index.13"
                                                defaultMessage="Consejo Mexicano de Oftalmología"
                                            />
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="padre_4 sombras_1">
                                <i style={{color:"#19B5CC", fontSize:"50px", paddingRight:"30px"}} class="fas fa-award"></i>
                                <div>
                                    <p style={{margin:"0px"}}>
                                        <FormattedMessage
                                            id="index.14"
                                            defaultMessage="Revista Forbes"
                                        />
                                    </p>
                                    <p style={{margin:"0px"}}>
                                        <FormattedMessage
                                            id="index.15"
                                            defaultMessage="Reconocimiento “Los 16 mejores doctores especialistas de México en el año 2015”: 
                                            la mejor oftalmóloga de México."
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hijo_3">
                        <p className="texto_1">
                            <FormattedMessage
                                id="index.5"
                                defaultMessage="cirujano oftamólogo "
                            /><br />
                            <FormattedMessage
                                id="index.5.1"
                                defaultMessage="alta especialidad en retina y vitreo"
                            />
                        </p>
                        <p className="titulo_2" style={{margin:"0px"}}>Dra. Paola Jacobo Oceguera</p>
                        <p>
                            <FormattedMessage
                                id="index.6"
                                defaultMessage="“Gracias por depositar la salud de sus ojos en "
                            />
                            <span>
                                <FormattedMessage
                                    id="index.6.1"
                                    defaultMessage="manos expertas"
                                />
                            </span>
                            <FormattedMessage
                                id="index.6.2"
                                defaultMessage=", puede estar seguro de que recibirá una adecuada evaluación y el mejor tratamiento. Estoy 
                                a sus órdenes y espero conocerle pronto.”"
                            />
                        </p>
                        <div className="padre_3">
                            <div>
                                <div className="padre_3 sombras_2">
                                    <i style={{color:"#19B5CC", fontSize:"30px", paddingRight:"20px"}} class="fas fa-check-circle"></i>
                                    <p>
                                        <FormattedMessage
                                            id="index.7"
                                            defaultMessage="Cirugía Oftalmológica"
                                        />
                                    </p>
                                </div>
                                <div className="padre_3 sombras_2">
                                    <i style={{color:"#19B5CC", fontSize:"30px", paddingRight:"20px"}} class="fas fa-check-circle"></i>
                                    <p>
                                        <FormattedMessage
                                            id="index.8"
                                            defaultMessage="Tratamiento de la "
                                        /><br />
                                        <FormattedMessage
                                            id="index.8.1"
                                            defaultMessage="Retinopatía Diabética"
                                        />
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="padre_3 sombras_2">
                                    <i style={{color:"#19B5CC", fontSize:"30px", paddingRight:"20px"}} class="fas fa-check-circle"></i>
                                    <p>
                                        <FormattedMessage
                                            id="index.9"
                                            defaultMessage="Cirugía de Catarata."
                                        />
                                    </p>
                                </div>
                                <div className="padre_3 sombras_2">
                                    <i style={{color:"#19B5CC", fontSize:"30px", paddingRight:"20px"}} class="fas fa-check-circle"></i>
                                    <p>
                                        <FormattedMessage
                                            id="index.10"
                                            defaultMessage="Cirugía de Retina y Vítreo"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="padre" style={{marginTop:"50px"}}>
                            <a href="/dra-paola-jacobo">
                                <div className="boton_2" style={{padding:"20px"}}>
                                    <FormattedMessage
                                        id="index.16"
                                        defaultMessage="Conocer más…"
                                    />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
    
                <div className="fondo_2">
                    <div>
                        <p className="texto_1" style={{color:"#ffffff"}}>
                            <FormattedMessage
                                id="index.17"
                                defaultMessage="Especialidades"
                            />
                        </p>
                        <p className="titulo_2" style={{color:"#ffffff", marginTop:"0px", marginLeft:"30px"}}>
                            <FormattedMessage
                                id="index.18"
                                defaultMessage="La Dra Paola Jacobo "
                            /><br />
                            <FormattedMessage
                                id="index.19"
                                defaultMessage="se especializa en:"
                            />
                        </p>
                    </div>
                </div>

                <div style={{position:"relative", top:"-180px"}}>
                    <div className="padre center">
                        <div className="padre sombras_4">
                            <div className="hijo_4">
                                <div className="sombras_3">
                                    <img src={icon3} alt="" style={{width:"100%"}} />
                                </div>
                                <p className="texto_2">
                                    <FormattedMessage
                                        id="index.20"
                                        defaultMessage="Cirugía de Retina y Vítreo"
                                    />
                                </p>
                            </div>
                            <div className="linea"></div>
                            <div className="hijo_4">
                                <div className="sombras_3">
                                    <img src={icon4} alt="" style={{width:"100%"}} />
                                </div>
                                <p className="texto_2">
                                    <FormattedMessage
                                        id="index.21"
                                        defaultMessage="Diagnóstico y Tratamiento de Enfermedades de la Retina."
                                    />
                                </p>
                            </div>
                            <div className="linea"></div>
                            <div className="hijo_4">
                                <div className="sombras_3">
                                    <img src={icon5} alt="" style={{width:"100%"}} />
                                </div>
                                <p className="texto_2">
                                    <FormattedMessage
                                        id="index.22"
                                        defaultMessage="Tratamiento de Retinopatía Diabética."
                                    />
                                </p>
                            </div>
                            <div className="linea"></div>
                            <div className="hijo_4">
                                <div className="sombras_3">
                                    <img src={icon6} alt="" style={{width:"100%"}} />
                                </div>
                                <p className="texto_2">
                                    <FormattedMessage
                                        id="index.23"
                                        defaultMessage="Tratamiento de Degeneración Macular Asociada a la Edad."
                                    />
                                </p>
                            </div>
                            <div className="linea"></div>
                            <div className="hijo_4">
                                <div className="sombras_3">
                                    <img src={icon7} alt="" style={{width:"100%"}} />
                                </div>
                                <p className="texto_2">
                                    <FormattedMessage
                                        id="index.24"
                                        defaultMessage="Cirugía de Catarata + Lente Intraocular."
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
        
                    <div className="container center" style={{marginTop:"100px"}}>
                        <p className="texto_1">
                            <FormattedMessage
                                id="index.25"
                                defaultMessage="servicios"
                            />
                        </p>
                        <p className="container titulo_2">
                            <FormattedMessage
                                id="index.26"
                                defaultMessage="Atención oftalmológica de alta calidad  para cuidar lo más valioso que tiene… "
                            />
                            <span style={{color:"#235677"}}>
                                <FormattedMessage
                                    id="index.27"
                                    defaultMessage="¡SU VISTA!"
                                />
                            </span>
                        </p>
                        <div className="padre">
                            {props.servicios.map(dato => (
                                <div className="hijo_servicios">
                                    <div>
                                        <img src={dato.path} alt="" style={{width:"100%"}} />
                                    </div>
                                    <div className="titulo_servicios">
                                        {idioma.obtenerLenguaje() === "es-MX" ? (
                                            <p><span>{dato.nombre_esp}</span></p>
                                        ) : (
                                            <p><span>{dato.nombre_ing}</span></p>
                                        )}
                                    </div>
                                    <div className="boton" style={{width:"100px"}}>
                                        <a href={`/servicios/${dato.id}`}>
                                            <p>
                                                <FormattedMessage
                                                    id="servicios.1"
                                                    defaultMessage="Ver más"
                                                />
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="padre" style={{marginTop:"50px", marginBottom:"-90px"}}>
                        <a href="/servicios" >
                            <div className="boton_1">
                                <p>
                                    <FormattedMessage
                                        id="index.28"
                                        defaultMessage="Ver todos los servicios"
                                    />
                                </p>
                            </div>
                        </a>
                    </div>
        
                    <div className="container" style={{marginTop:"150px"}}>
                        <div className="padre">
                            <div className="contenedor_galeria_2" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <p className="titulo_1">
                                    <FormattedMessage
                                        id="index.29"
                                        defaultMessage="GALERIA"
                                    />
                                </p>
                            </div>
                            {props.galeria.map(dato => (
                                <div className="contenedor_galeria_2">
                                    <img className="materialboxed imagen_galeria" src={dato.path} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Index.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'galeria',
            limit: 5
        },
        {
            collection: 'servicios',
            limit: 3
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        galeria: ordered.galeria,
        servicios: ordered.servicios
    }))
)(Index);

