import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import CryptoJS from "react-native-crypto-js";
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';
import { useParams } from "react-router-dom";

class EditarUsuario extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            correo: '',
            password: '',
            tipo: '',
            tipoMostrar: '',
            nombre: ''
        }
    }

    componentDidUpdate = () => {
        if (this.state.nombre === '') {
            this.setState({
                password: this.props.user.password,
                nombre: this.props.user.nombre,
                correo: this.props.user.correo,
                tipo: this.props.user.tipo
            });
        }
    }
    
    editar = e => {
        const { history } = this.props;
        e.preventDefault();

        var tipoMostrar;

        if (this.state.tipo === '0') {
            tipoMostrar = "Administrador"
        } else if (this.state.tipo === '1') {
            tipoMostrar = "Otro"
        }

        if (this.state.password !== this.props.user.password) {
            let ciphertext = CryptoJS.AES.encrypt(this.state.password, 'Y2Fhdg==').toString();
            this.setState({
                password: ciphertext
            });
        }

        this.setState({
            tipoMostrar: tipoMostrar
        }, () => {
            //extraer valores del state
            var editar = {...this.state};
    
            //extraer firestor de props
            const { firestore } = this.props;
    
            //actualizacion en la base de datos
            firebaseHelper.editar(this.props.user.id, 'usuario', firestore, editar, '/admin/usuarios', history);
        });
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    render(){
        if (!this.props.user) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="EDITAR USUARIO" />
                    <div id="font-text" className="container center" style={{marginTop:'0px'}}>
                            <form onSubmit={this.editar}>
                                <div style={{margin:'0 15%'}}>
                                    <p id="font-text-b" >Tipo Usuario</p>
                                    <div id="tipo" style={{display:'flex', justifyContent:'center'}}>
                                        <div className="row">
                                            <p style={{marginLeft:'10px'}}>
                                                <label style={{marginLeft:'30px'}}>
                                                    {this.state.tipo === '0' ? (
                                                        <input checked required onChange={this.leerDato} value='0' class="with-gap" name="tipo" type="radio"  />
                                                    ) : (
                                                        <input required onChange={this.leerDato} value='0' class="with-gap" name="tipo" type="radio"  />
                                                    )}
                                                    <span>Administrador</span>
                                                </label>
                                            </p>
                                            <p>
                                                <label style={{marginLeft:'30px'}}>
                                                    {this.state.tipo === '1' ? (
                                                        <input checked required onChange={this.leerDato} value='1' class="with-gap" name="tipo" type="radio"  />
                                                    ) : (
                                                        <input required onChange={this.leerDato} value='1' class="with-gap" name="tipo" type="radio"  />
                                                    )}
                                                    <span>Otros</span>
                                                </label>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} value={this.state.nombre} name="nombre" id="nombre" type="text" class="validate" />
                                        <label class="active" for="nombre">Nombre</label>
                                    </div>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} name="correo" value={this.state.correo} id="correo" type="email" class="validate" />
                                        <label class="active" for="correo">Correo</label>
                                    </div>
                                    <div class="input-field col s12">
                                        <input onChange={this.leerDato} name="password" id="password" type="password" class="validate" />
                                        <label for="password">Password</label>
                                    </div>
                                </div>
                                <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#235677 '}}>EDITAR</button>
                                <Link to={'/admin/usuarios'} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#235677 '}}>REGRESAR</Link>
                            </form>
                    </div>
                </Fragment>
            );
        }
    }
}

EditarUsuario.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'usuario',
            storeAs: 'user',
            doc: window.location.pathname.split('/')[3]
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        user: ordered.user && ordered.user[0]
    }))
)(EditarUsuario)