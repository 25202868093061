import Swal from 'sweetalert2';

var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
    url_json = url_json.local;
} else {
    url_json = url_json.production;
}

const firebaseHelper = {
    agregarEvento: async data => {
        let url = url_json + "/crear-evento-api"
        let body = {
            fechaInicio: data.fechaInicio,
            fechaFinal: data.fechaFinal,
            descripcion: data.descripcion,
            titulo: data.titulo,
            ubicacion: data.ubicacion
        };
        let request = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let res = await response.json();
        return res;
    },
    enviarSMS: async data => {
        let url = url_json + "/enviar-sms"
        let body = {
            numero: data.numero,
            mensaje: data.mensaje,
        };
        let request = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let res = await response.json();
        return res;
    }
}

export default firebaseHelper;