import React, {Fragment, Component, useState}  from 'react';
import ReactDOM from 'react-dom';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const NuevaImagen = props => {
    const [state, setState] = useState({
        file1: '',
        picture: null,
        prueba: ''
    });

    // const firebase = useFirebase();

    const handelUpload = e => {
        e.preventDefault();

        setState({
            ...state,
            file1: e.target.files[0]
        })

        precarga(e.target.files[0]);
    }

    const precarga = (file) => {
        var imageType = /image.*/;
        
        if (!file.type.match(imageType))
        return;
    
        var reader = new FileReader();
    
        reader.onload = function(e){
            var result = e.target.result;
            document.getElementById("imgSalida").src = result;
        }
        
        reader.readAsDataURL(file);
    }

    const subirDB = async file => {
        const { firestore } = props;
        var file1 = state.file1;

        if (file === '1') {
            preloader();

            const storage = getStorage();
            const storageRef = ref(storage, `/images/servicios/${props.servicios.nombre_esp}`);

            const uploadTask = uploadBytesResumable(storageRef, file1);

            // Register three observers:
            // 1. 'state_changed' observer, called any time the state changes
            // 2. Error observer, called on failure
            // 3. Completion observer, called on successful completion
            uploadTask.on('state_changed', 
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 800;

                    setState({
                        ...state,
                        uploadValue: progress
                    })
                }, 
                (error) => {
                    // Handle unsuccessful uploads
                    Swal.fire(
                        'Error',
                        'Error al subir el archivo',
                        'error'
                    )
                }, 
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        const servicios = {
                            path: downloadURL
                        }
                        //guardado en la base de datos
                        firestore.update({
                            collection: 'servicios',
                            doc: window.location.pathname.split('/')[3]
                        }, servicios ).then(() => editado());
                    });
                }
            );
        }
    }

    const editado = () => {
        Swal.fire(
            'Inserción exitosa',
            'Imagen insertada correctamente',
            'success'
        ).then(() => {
            window.location.replace('/admin/imagenes');
        });
    }

    const preloader = () => {
        var preloader = 
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div><div class="gap-patch">
                    <div class="circle"></div>
                </div><div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>;
        ReactDOM.render(preloader, document.getElementById('preloader'));
    }
    
    if (!props.servicios) {
        return (
            <Fragment>
                <Header />
                <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-red darken-4-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <Header mensaje="NUEVA IMAGEN SERVICIO" />
                <div className="container center-align" style={{marginTop:'50px'}}>
                    <div class="progress">
                        <div class="determinate" style={{width:state.uploadValue}}></div>
                    </div>
                    <div id="preloader" style={{display:"flex", justifyContent:"center", marginBottom:'30px', justifyItems:"center"}}>
                        
                    </div>
                    <div className="row">
                        <div className="col s4">    
                            <div className="file-field input-field">
                                <div className="btn">
                                    <span>File</span>
                                    <input onChange={handelUpload} name="file" type="file"/>
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path validate" type="text" placeholder="Seleccione una foto o arrastre una foto"/>
                                </div>
                            </div>
                            <div className=" btn-outline-light center" style={{padding: '30px 0 30px 0', display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                <div id="font" class="btn waves-effect waves-light" onClick={() => subirDB('1')} style={{padding:'0 50px 0 50px', background:'#235677 '}}>AGREGAR</div>
                                <Link to={`/admin/imagenes`} id="font" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginTop:'20px', background:'#235677 '}}>REGRESAR</Link>
                            </div>
                        </div>
                        <div className="col s8">
                            <img alt="imagen subida" id="imgSalida" width="100%" src=""></img>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

NuevaImagen.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'servicios',
            doc: window.location.pathname.split('/')[3]
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        servicios: ordered.servicios && ordered.servicios[0]
    }))
)(NuevaImagen);