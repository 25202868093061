import React, {Fragment, Component}  from 'react';
import { FormattedMessage } from 'react-intl';

import img7 from "../Images/7.png";
import img8 from "../Images/8.png";

import icon3 from "../Images/icons/3.png";
import icon4 from "../Images/icons/4.png";
import icon5 from "../Images/icons/5.png";
import icon6 from "../Images/icons/6.png";
import icon7 from "../Images/icons/7.png";

import face from "../Images/icons/face.png";
import whats from "../Images/icons/whats.png";
import insta from "../Images/icons/insta.png";


class Nosotros extends Component {

    constructor(props){
        super(props);
        this.state = {}
      }

      render (){
          return (
            <Fragment>
                <div className="fondo_4"></div>
                <div className="container padre" style={{marginTop:"150px"}}>
                    <div className="hijo_5">
                        <div>
                            <img src={img8} alt="" style={{width:"100%"}} />
                            <div className="sombras_6">
                                <div className="div_nosotros">
                                    <a href="https://www.facebook.com/Dra-Paola-Jacobo-Oceguera-125158903079603" target="blanck">
                                        <img src={face} alt="" style={{width:"30px"}} />
                                    </a>
                                    <a href="https://www.instagram.com/dra.paola_jacobo_oceguera/" target="blanck">
                                        <img src={insta} alt="" style={{width:"30px"}} />
                                    </a>
                                    <a href="https://wa.me/523320263649?text=¡Hola buen día! ¿Me puede brindar información de sus servicios?" target="blanck">
                                        <img src={whats} alt="" style={{width:"30px"}} />
                                    </a>
                                </div>
                                <a target="blanck" href="tel:3311885959">
                                    <div className="padre_3">
                                        <i class="fas fa-phone" style={{fontSize:"30px", marginRight:"20px", color:"#235677"}}></i>
                                        <p style={{color:"#000000"}}>(322) 3490468</p>
                                    </div>
                                </a>
                                <div className="linea_1"></div>
                                <a target="blanck" href="mailto:drajacobo@oftalretina.com?Subject=¡Hola!&body=¡Hola%20buen%20día!%20Me%20podira%20brindar%20información%20acerca%20de%20de%20sus%20servicios.">
                                    <div className="padre_3" style={{marginBottom:"50px"}}>
                                        <i class="far fa-envelope" style={{fontSize:"30px", marginRight:"20px", color:"#235677"}}></i>
                                        <p style={{color:"#000000"}}>drajacobo@oftalretina.com</p>
                                    </div>
                                </a>
                                <a href="/contacto-guadalajara" style={{display:"grid"}}>
                                    <div className="boton_2" style={{width:"100%", textAlign:"center"}}>
                                        <FormattedMessage
                                            id="nosotros.7.1"
                                            defaultMessage="Haz una cita"
                                        />{" Guadalajara"}
                                    </div>
                                </a>
                                <a href="/contacto-vallarta" style={{display:"grid", marginTop:"10px"}}>
                                    <div className="boton_2" style={{width:"100%", textAlign:"center"}}>
                                        <FormattedMessage
                                            id="nosotros.7.1"
                                            defaultMessage="Haz una cita"
                                        />{" Nv. Vallarta"}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="hijo_6"  id="inicio" style={{padding:"0px 25px"}}>
                        <p className="titulo_1 grueso_1">Dra. Paola Jacobo Oceguera</p>
                        <p className="texto_1">
                            <FormattedMessage
                                id="nosotros.1"
                                defaultMessage="CIRUJANO OFTAMÓLOGO"
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="nosotros.2"
                                defaultMessage="La Dra. Paola Jacobo es egresada de la Universidad de Guadalajara, donde culminó su 
                                licenciatura como Médico Cirujano y Partero, con distinción por Excelente Desempeño 
                                Académico y Estudiante sobresaliente. Obtuvo el Título de Médico Cirujano y Partero 
                                por Excelencia Académica, y la cédula profesional por parte de la Dirección General 
                                de Profesiones."
                            />
                        </p>
                        <p>
                            <span>
                                <FormattedMessage
                                    id="nosotros.3"
                                    defaultMessage="Obtuvo el Primer Lugar "
                                />
                            </span> 
                            <FormattedMessage
                                id="nosotros.4"
                                defaultMessage="en el XXXIII Examen Nacional de Aspirantes a 
                                Residencias Médicas para la especialidad de Oftalmología."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="nosotros.5"
                                defaultMessage="Posteriormente, realizó su especialidad médica como Cirujano Oftalmólogo en la Unidad 
                                Oftalmológica de Alta Especialidad, sede del programa académico de la Universidad de 
                                Guadalajara, donde fungió como Jefe de Residentes durante el tercer año de su especialidad. 
                                Obtuvo la cédula profesional de especialidad por parte de la Dirección General de Profesiones."
                            />
                        </p>
                        <p><span>
                            <FormattedMessage
                                id="nosotros.6"
                                defaultMessage="Obtuvo el Primer Lugar en la Certificación por parte del Consejo Mexicano de Oftalmología 
                                y la autorización para ejercer la Especialidad de Oftalmología."
                            />
                        </span></p>
                        <p>
                            <FormattedMessage
                                id="nosotros.7"
                                defaultMessage="Durante dos años más, cursó la Alta Especialidad en Retina y Vítreo en la Asociación 
                                Para Evitar la Ceguera en México I.A.P., Hospital “Dr. Luis Sánchez Bulnes”, sede 
                                clínica del Programa Académico de la Universidad Nacional Autónoma de México (UNAM), 
                                donde realizó su entrenamiento tanto médico como quirúrgico, así como múltiples 
                                actividades de investigación en el campo de las enfermedades que afectan la retina."
                            />
                        </p>
                    </div>
                </div>

                <div className="fondo_3 center">
                    <p className="titulo_2" style={{paddingTop:"50px"}}>
                        <FormattedMessage
                            id="index.17"
                            defaultMessage="Especialidades"
                        />
                    </p>
                    <div className="padre">
                        <div className="div_nosotros_servicios">
                            <img src={icon3} alt="" style={{width:"150px"}} />
                            <p className="texto_2">
                                <FormattedMessage
                                    id="index.20"
                                    defaultMessage="Cirugía de Retina y Vítreo"
                                />
                            </p>
                        </div>
                        <div className="div_nosotros_servicios">
                            <img src={icon4} alt="" style={{width:"150px"}} />
                            <p className="texto_2">
                                <FormattedMessage
                                    id="index.21"
                                    defaultMessage="Diagnóstico y Tratamiento de Enfermedades de la Retina."
                                />
                            </p>
                        </div>
                        <div className="div_nosotros_servicios">
                            <img src={icon5} alt="" style={{width:"150px"}} />
                            <p className="texto_2">
                                <FormattedMessage
                                    id="index.22"
                                    defaultMessage="Tratamiento de Retinopatía Diabética."
                                />
                            </p>
                        </div>
                        <div className="div_nosotros_servicios">
                            <img src={icon6} alt="" style={{width:"150px"}} />
                            <p className="texto_2">
                                <FormattedMessage
                                    id="index.23"
                                    defaultMessage="Tratamiento de Degeneración Macular Asociada a la Edad."
                                />
                            </p>
                        </div>
                        <div className="div_nosotros_servicios">
                            <img src={icon7} alt="" style={{width:"150px"}} />
                            <p className="texto_2">
                                <FormattedMessage
                                    id="index.24"
                                    defaultMessage="Cirugía de Catarata + Lente Intraocular."
                                />
                            </p>
                        </div>
                    </div>
                </div>


                <div className="container padre" style={{marginTop:"100px", marginBottom:"100px"}}>
                    <div className="sombras_7 padre_2">
                        <i style={{color:"#19B5CC", fontSize:"100px"}} class="fas fa-award"></i>
                        <div>
                            <p style={{fontSize:"25px"}}>
                                <FormattedMessage
                                    id="nosotros.8"
                                    defaultMessage="Revista Forbes"
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id="nosotros.8.1"
                                    defaultMessage="Reconocimiento “Los 16 mejores doctores especialistas de México en el año 2015”: la mejor oftalmóloga de México."
                                />
                            </p>
                        </div>
                    </div>
                    <div className="sombras_7 padre_2">
                        <i style={{color:"#19B5CC", fontSize:"100px"}} class="fas fa-award"></i>
                        <div>
                            <p style={{fontSize:"25px"}}>
                                <FormattedMessage
                                    id="nosotros.9"
                                    defaultMessage="Premio Doctoralia Awards"
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id="nosotros.9.1"
                                    defaultMessage="“Oftalmóloga mejor valorada de México en el año 2015”."
                                />
                            </p>
                        </div>
                    </div>
                    <div className="sombras_7 padre_2">
                        <i style={{color:"#19B5CC", fontSize:"100px"}} class="fas fa-award"></i>
                        <div>
                            <p style={{fontSize:"25px"}}>
                                <FormattedMessage
                                    id="nosotros.10"
                                    defaultMessage="Certificación por Aprobación"
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id="nosotros.10.1"
                                    defaultMessage="“Examen de Certificación en Oftalmología”, del “Consejo Mexicano de Oftalmología”, en el que obtuvo el Primer Lugar. Número de Socio: 3132."
                                />
                            </p>
                        </div>
                    </div>
                    <div className="sombras_7 padre_2">
                        <i style={{color:"#19B5CC", fontSize:"100px"}} class="fas fa-award"></i>
                        <div>
                            <p style={{fontSize:"25px"}}>
                                <FormattedMessage
                                    id="nosotros.11"
                                    defaultMessage="Especialidad de Oftalmología."
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id="nosotros.11.1"
                                    defaultMessage="“Primer Lugar en el XXXIII Examen Nacional de Aspirantes a Residencias Médicas”"
                                />
                            </p>
                        </div>
                    </div>
                    <div className="sombras_7 padre_2">
                        <i style={{color:"#19B5CC", fontSize:"100px"}} class="fas fa-award"></i>
                        <div>
                            <p style={{fontSize:"25px"}}>
                                <FormattedMessage
                                    id="nosotros.12"
                                    defaultMessage="Centro Universitario de Ciencias de la Salud, Universidad de Guadalajara."
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id="nosotros.12.1"
                                    defaultMessage="“Tercer Lugar de la Generación de Médicos Cirujanos y Parteros”"
                                />
                            </p>
                        </div>
                    </div>
                    <div className="sombras_7 padre_2">
                        <i style={{color:"#19B5CC", fontSize:"100px"}} class="fas fa-award"></i>
                        <div>
                            <p style={{fontSize:"25px"}}>
                                <FormattedMessage
                                    id="nosotros.13"
                                    defaultMessage="Centro Universitario de Ciencias de la Salud, Universidad de Guadalajara."
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id="nosotros.13.1"
                                    defaultMessage="“Excelencia Académica en la Carrera de Médico Cirujano y Partero”"
                                />
                            </p>
                        </div>
                    </div>
                    <div className="sombras_7 padre_2">
                        <i style={{color:"#19B5CC", fontSize:"100px"}} class="fas fa-award"></i>
                        <div>
                            <p style={{fontSize:"25px"}}>
                                <FormattedMessage
                                    id="nosotros.14"
                                    defaultMessage="Centro Universitario de Ciencias de la Salud, Universidad de Guadalajara."
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id="nosotros.14.1"
                                    defaultMessage="“Excelente Desempeño Académico y Estudiante Sobresaliente de la carrera de Médico Cirujano y Partero”"
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Nosotros;
