import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import CryptoJS from "react-native-crypto-js";
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';


class EditarUsuario extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            horas:['0:00', '0:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30']
        }
    }


    render(){
        if (!this.props.citas) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje={"CITA: " + this.props.citas.dia + " " + this.state.horas[this.props.citas.hora]} />
                    <div className="container">
                        <h1 className="titulo_3">{this.props.citas.nombre} {this.props.citas.apellido}</h1>
                        <p>Lugar: {this.props.citas.lugar}</p>
                        <p>Correo: <a href="">{this.props.citas.email}</a></p>
                        <p>Telefono: <a href="">{this.props.citas.telefono}</a></p>
                        <p>
                            ¿Tien fiebre, tos, falta de aire o dificultad para respirar, o ha estado en contacto con 
                            alguien que pueda presentar alguno de estos síntomas? <br /><span>{this.props.citas.enfermedad}</span>
                        </p>
                        <p>
                            ¿Es su primera visita? <br /><span>{this.props.citas.visita}</span>
                        </p>
                        {this.props.citas.comentario ? (
                            <p>Comentarios: <br />{this.props.citas.comentario}</p>
                        ) : (
                            <span></span>
                        )}
                        <Link to={'/admin/citas'} id="font-button" class="btn waves-effect waves-light" 
                            style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#235677 ', marginTop:"50px"}}>REGRESAR</Link>
                    </div>
                </Fragment>
            );
        }
    }
}

EditarUsuario.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'citas',
            doc: window.location.pathname.split('/')[3]
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        citas: ordered.citas && ordered.citas[0]
    }))
)(EditarUsuario)