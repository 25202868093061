import React, {Fragment, useEffect, useState}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Swal from 'sweetalert2';
import CryptoJS from "react-native-crypto-js";
import encriptar from  '../Helpers/Encriptar';

import maps from "../Images/icons/maps.svg";
import correo from "../Images/icons/correo.svg";
import tel from "../Images/icons/tel.svg";

// import InfiniteCalendar from 'react-infinite-calendar';
// import 'react-infinite-calendar/styles.css'; // Make sure to import the default stylesheet
import InfiniteCalendar from '@appannie/react-infinite-calendar';
import '@appannie/react-infinite-calendar/styles.css'; // Make sure to import the default stylesheet

const Contacto = props => {
    const [state, setState] = useState({
        diasVallarta: [],
        diasGuadalajara: [],
        horas:['0:00', '0:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
        horas_mostrar: [],
        date_cita: new Date(),
        hora_cita: "",
        candado: true
    }); 

    useEffect(() => {
        var horas_agendadas = [];
        
        if (state.horas_mostrar.length === 0) {
            if (props.citas) {
                var horas = [...state.horas];
                var horas_props, horas_mostrar = [];
                
                switch (new Date().getDay()) {
                    case 1:
                        horas_props = props.horarios.horario_lunes;
                        break;
                    case 2:
                        horas_props = props.horarios.horario_martes;
                        break;
                    case 3:
                        horas_props = props.horarios.horario_miercoles;
                        break;
                    case 4:
                        horas_props = props.horarios.horario_jueves;
                        break;
                    case 5:
                        horas_props = props.horarios.horario_viernes;
                        break;
                    case 6:
                        horas_props = props.horarios.horario_sabado;
                        break;
                    case 0:
                        horas_props = props.horarios.horario_domingo;
                        break;
                }


                props.citas.forEach((doc) => {
                    horas_agendadas.push(doc.hora);
                });
    
                for (let index_agendas = 0; index_agendas < horas_agendadas.length; index_agendas++) {
                    horas.splice(horas_agendadas[index_agendas], 1);
                }
    
                for (let horarios_index = 0; horarios_index< horas_props.length; horarios_index++) {
                    for (let index = 0; index < horas.length; index++) {
                        if (index >= horas_props[horarios_index].inicio && index <= horas_props[horarios_index].fin) {
                            horas_mostrar.push(horas[index])
                        }
                    }
                }

                if (state.diasGuadalajara.length === 0 && state.diasVallarta.length === 0) {
                    console.log("entro");
                    var diasVallarta = [], diasGuadalajara = [];
                    for (let index = 0; index < props.calendario.diasVallarta.length; index++) {
                        diasVallarta.push(new Date(props.calendario.diasVallarta[index].seconds * 1000));
                    }
                    for (let index = 0; index < props.calendario.diasGuadalajara.length; index++) {
                        diasGuadalajara.push(new Date(props.calendario.diasGuadalajara[index].seconds * 1000));
                    }
                }

                console.log({
                    diasVallarta,
                    diasGuadalajara,
                });

                setState({
                    ...state,
                    diasVallarta,
                    diasGuadalajara,
                    horas_mostrar: horas_mostrar
                });

                window.scroll(0,0);
            }
        }

        // console.log(props);
    });

    //primer paso para agendar
    const dia = date => {
        const { firestore } = props;
        var horas_agendadas = [];

        function consulta1() {
            return new Promise(resolve => {
                firestore.collection("citas")
                .where("dia", "==", date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate())
                .get()
                .then(function(querySnapshot) {
                    if (querySnapshot.docs.length === 0) {
                        resolve(false);
                    } else {
                        resolve(querySnapshot);
                        /*querySnapshot.forEach(function (doc) {
                            console.log(doc.id, ' => ', doc.data());
                        });*/
                    }
                }).catch(function(error) {
                    resolve(false);
                });
            })
        }

        async function guardarState1() {
            const acceso =  consulta1();
            return acceso;
        }

        guardarState1().then(res => {
            if (res) {
                res.forEach((doc) => {
                    horas_agendadas.push(doc.data().hora);
                });
                horas_disponibles(horas_agendadas, date);
            } else {
                horas_disponibles([], date);
            }
        })
    }

    //segundo paso para agendar
    const horas_disponibles = (horas_agendadas, date) => {
        var horas = [...state.horas];
        var horas_props, horas_mostrar = [];

        switch (date.getDay()) {
            case 1:
                horas_props = props.horarios.horario_lunes;
                break;
            case 2:
                horas_props = props.horarios.horario_martes;
                break;
            case 3:
                horas_props = props.horarios.horario_miercoles;
                break;
            case 4:
                horas_props = props.horarios.horario_jueves;
                break;
            case 5:
                horas_props = props.horarios.horario_viernes;
                break;
            case 6:
                horas_props = props.horarios.horario_sabado;
                break;
            case 7:
                horas_props = props.horarios.horario_domingo;
                break;
        }


        for (let index_agendas = 0; index_agendas < horas_agendadas.length; index_agendas++) {
            horas.splice(horas_agendadas[index_agendas], 1);
        }


        for (let horarios_index = 0; horarios_index< horas_props.length; horarios_index++) {
            for (let index = 0; index < horas.length; index++) {
                if (index >= horas_props[horarios_index].inicio && index <= horas_props[horarios_index].fin) {
                    horas_mostrar.push(horas[index])
                }
            }
        }

        setState({
            ...state,
            horas_mostrar: horas_mostrar,
            date_cita: date
        });
    }

    const hora = e => {
        var select = document.getElementById("horas_selected");
        if (select) {
            select.className = ""
            select.id = ""
        }
        e.target.className = "horas_selected";
        e.target.id = "horas_selected";

        setState({
            ...state,
            hora_cita: e.target.value
        });
    }

    const agendar = (lugar) => {
        const fecha = state.date_cita, hora = state.hora_cita;
        if (hora === "") {
            Swal.fire({
                title: 'Error',
                text: 'Escoje una hora valida',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon: 'error'
            })
        } else {
            // const { history } = props;
            //document.getElementById("notificacion").className = "visible";
            const data = fecha.getFullYear() + "-" + fecha.getMonth() + "-" + fecha.getDate()  + "_" +  hora  + "_" +  lugar;
            let ciphertext = CryptoJS.AES.encrypt(data, 'Y2Fhdg==').toString();
            //console.log(encriptar.encode(ciphertext));
            window.location.replace(`/agenda-una-cita/${encriptar.encode(ciphertext)}`);
            // history.push(`/agenda-una-cita/${encriptar.encode(ciphertext)}`)
        }
    }

    const salirNotificacion = () => {
        document.getElementById("notificacion").className = "invisible";
    }

    if (!props.citas) {
        return (
            <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                <div class="preloader-wrapper big active">
                    <div class="spinner-layer spinner-red darken-4-only">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="gap-patch">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return ( 
            <div style={{background:"#F8F8F8", paddingBottom:"100px"}}>
                <div className="fondo_7"></div>

                <div className="container" style={{marginBottom:"0px"}}>
                    <p className="titulo_3 center" style={{marginTop:"0px"}}>
                        Nuevo Vallarta, Nay.
                    </p>

                    <p className="titulo_1">
                        <FormattedMessage
                            id="contacto_tra.7"
                            defaultMessage="Contáctanos, te esperamos."
                        />
                    </p>
                    <div className='row'>
                        <a target="blanck" href="https://g.page/hospitaljoyariviera?share">
                            <div className='col s12 m12 l4'>
                                <div className='col s12'>
                                    <div className='icons_contacto'>
                                        <img src={maps} alt="" style={{height:"100%"}} />
                                    </div>
                                </div>
                                <div style={{color:"black"}} className='col s12'>
                                    <p style={{fontWeight:"bolder"}}>
                                        <FormattedMessage
                                            id="contacto_tra.1"
                                            defaultMessage="Dirección"
                                        />
                                    </p>
                                    <p>
                                        <span>HOSPITAL JOYA RIVIERA NAYARIT (NÚCLEO MÉDICO JOYA):</span><br />
                                        Paseo de los Cocoteros No. 55, Fraccionamiento Náutico Turístico.
                                    </p>
                                </div>
                            </div>
                        </a>
                        <div className='col s12 m12 l4'>
                            <div className='col s12'>
                                <div className='icons_contacto'>
                                    <img src={tel} alt="" style={{width:"100%"}} />
                                </div>
                            </div>
                            <div className='col s12'>
                                <p style={{fontWeight:"bolder"}}>
                                    <FormattedMessage
                                        id="contacto_tra.2"
                                        defaultMessage="Teléfono"
                                    />
                                </p>
                                <p>
                                    <a target="blanck" href="tel:3223490468" style={{color:"black"}}>(322) 3490468</a> / <a target="blanck" href="tel:3226887701" style={{color:"black"}}>(322) 6887701</a> <br />
                                    <span>Whatsapp:</span> <a target="blanck" href="https://wa.me/523223490468?text=¡Hola buen día! ¿Me puede brindar información de sus servicios?" style={{color:"black"}}>(322) 3490468.</a> <br />
                                    <span>
                                        <FormattedMessage
                                            id="contacto_tra.3"
                                            defaultMessage="Urgencias"
                                        />:
                                    </span> <a target="blanck" href="tel:3312450644" style={{color:"black"}}>(33) 12450644</a>.
                                </p>
                            </div>
                        </div>
                        <div className='col s12 m12 l4'>
                            <div className='col s12'>
                                <div className='icons_contacto'>
                                    <img src={correo} alt="" style={{width:"100%"}} />
                                </div>
                            </div>
                            <div className='col s12'>
                                <p style={{fontWeight:"bolder"}}>E-mail</p>
                                <a target="blanck" href="mailto:drajacobo@oftalretina.com?Subject=¡Hola!&body=¡Hola%20buen%20día!%20Me%20podira%20brindar%20información%20acerca%20de%20de%20sus%20servicios.">
                                    <p style={{color:"black"}}>drajacobo@oftalretina.com</p>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="container" style={{marginBottom:"70px", marginTop:"70px"}}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.317334227948!2d-105.29802854038726!3d20.697334631588035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842146a49f13164d%3A0x58d0d5a503433441!2sHospital%20Joya%20Riviera%20Nayarit!5e0!3m2!1ses-419!2smx!4v1632379693979!5m2!1ses-419!2smx" style={{width:"100%", height:"240px", border:"0", loading:"lazy", allowfullscreen:""}}></iframe>
                    </div>

                    <p className="titulo_1">
                        <FormattedMessage
                            id="contacto_tra.4"
                            defaultMessage="¡Agenda una cita!"
                        />
                    </p>

                    <div style={{marginBottom:"50px"}}>
                        <p className="titulo_1 grueso_1" style={{textAlign:"left", marginBottom:"0"}}>
                            <FormattedMessage
                                id="contacto_tra.6"
                                defaultMessage="Fechas disponibles"
                            />
                            {state.diasVallarta.length > 0 ? (
                                <InfiniteCalendar
                                    locale={{
                                        weekStartsOn: 1,
                                        // locale: require('date-fns/locale/es'),
                                        weekdays: ["Dom","Lun","Mar","Mir","Jue","Vie","Sab"],
                                        blank: 'Sin fecha seleccionada',
                                        todayLabel: {
                                            long: 'Hoy dia\'hoy',
                                            short: 'Hoy'
                                        }
                                    }}
        
                                    //tamanio
                                    width={"100%"}
                                    height={window.innerHeight - 250}
                                    rowHeight={70}
                                    //fin tamanio
                                    //disenio
                                    theme={{
                                        selectionColor: '#22639A',
                                        textColor: {
                                            default: '#333',
                                            active: '#FFF'
                                        },
                                        weekdayColor: '#19B5CC',
                                        headerColor: '#19B5CC',
                                        floatingNav: {
                                            background: '#22639A',
                                            color: '#FFF',
                                            chevron: '#22639A'
                                        }
                                    }}
                                    //fin de disenio
        
                                    //restricciones de fechas
                                    min={new Date()} // Minimum month to render
                                    minDate={new Date()} // Minimum selectable date
                                    max={new Date().setHours(720)} // Maximum rendered month
                                    maxDate={new Date().setHours(720)} // Maximum selectable date
                                    disabledDates={state.diasVallarta}
                                    //fin de restricciones de fechas
        
                                    //accion al seleccionar dia
                                    onSelect={date => {dia(date)}}
                                    //fin de accion al seleccionar dia
        
                                    //desabilitacion de dias de trabajo
                                    disabledDays={props.horarios.dias_semana}
                                    //fin de desabilitacion de dias de trabajo
                                />
                            ) : (
                                <h1>cargando...</h1>
                            )}
                        </p>
                    </div>

                    <div>
                        <p className="titulo_1 grueso_1" style={{textAlign:"left", marginBottom:"0"}}>
                            <FormattedMessage
                                id="contacto_tra.5"
                                defaultMessage="¿A qué hora quieres agendar tu cita?"
                            />
                        </p>
                        <div className="horas">
                            {state.horas_mostrar.map(dato => (
                                <div>
                                    <button value={dato} onClick={hora} >{dato}</button>
                                </div>
                            ))}
                        </div>
                        <button className="boton" style={{width:"150px", textAlign:"center", height:"70px", border:"none", marginTop:"50px"}} onClick={() => {agendar("Vallarta")}}>
                            <FormattedMessage
                                id="header.6"
                                defaultMessage="Agenda una cita"
                            />
                        </button>
                    </div>
                </div>

                <div id="notificacion" className="invisible" onClick={salirNotificacion} style={{position:"fixed", zIndex:"9999", top:"0%", width:"100%", display:"flex", justifyContent:"center", alignItems:"center", height:"1080px"}}>
                    <div id="notificacion_hijo" className="notificacion">
                        <div style={{display:"flex", justifyContent:"flex-end", position:"relative", top:"-35px"}}>
                            <i class="fas fa-times" style={{position:"relative", top:"10px", right:"30px"}} onClick={salirNotificacion}></i>
                        </div>
                        <p>
                            <FormattedMessage
                                id="enviado.3"
                                defaultMessage="Cita no disponible"
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="enviado.4"
                                defaultMessage="Contactanos por telefon."
                            />
                        </p>
                    </div>
                </div>
            </div>
        )
    }
};

Contacto.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'horarios',
            doc: "2rFyHG8As9UCaARJlpVX"
        },
        { 
            collection: 'horarios',
            doc: "diasBloqueados",
            storeAs: "calendario"
        },
        { 
            collection: 'citas',
            where: [
                "dia", "==", new Date().getFullYear() + "-" + new Date().getMonth() + "-" + new Date().getDate()
            ]
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        horarios: ordered.horarios && ordered.horarios[0],
        calendario: ordered.calendario && ordered.calendario[0],
        citas: ordered.citas
    }))
)(Contacto);

