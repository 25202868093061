import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';


class Servicios extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nombre: ''
        }
    }

    eliminar = (id) => {
        const { firestore } = this.props;
        const { firebase } = this.props;

        firebaseHelper.eliminar(id, 'servicios', firestore, firebase);
        
    }


    render(){
        if (!this.props.servicios) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="SERVICIOS" />
                    <div className="btn-outline-light container" style={{padding: '30px 0 30px 0'}}>
                        <Link to={`/admin/nuevo-servicio`} id="font" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', background:'#235677 '}}>AGREGAR SERVICIO</Link>
                    </div>
                    <div className="container">
                        <Table striped bordered hover style={{marginBottom:'50px'}}>
                            <thead>
                                <tr>
                                    <th>Titulo Esp.</th>
                                    <th>Titulo Ing.</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody id="font">
                                {this.props.servicios.map(dato => (
                                    <tr key={dato.id}>
                                        <td>{dato.nombre_esp}</td>
                                        <td>{dato.nombre_ing}</td>
                                        <td>
                                            <a style={{marginLeft:'14%'}} href={`/admin/editar-servicio/${dato.id}`}><i class="material-icons" style={{color:'#235677 '}}>create</i></a>
                                            <div onClick={ () => this.eliminar(dato.id)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#235677 '}}>delete</i></div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    
                </Fragment>
            );
        }
    }
}

Servicios.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'servicios'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        servicios: ordered.servicios
    }))
)(Servicios);