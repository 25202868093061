import React, {Fragment, useContext, useEffect} from 'react';
import M from 'materialize-css';
import { langContext } from '../Context/langContext';
import { FormattedMessage } from 'react-intl';

import logo from "../Images/logo_1.png";
import es from "../Images/es.png";
import en from "../Images/en.png";

const Header = () => {
    useEffect(() => {
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems);

        var dropdown = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(dropdown, {
            coverTrigger: false
        });
    });

    const idioma = useContext(langContext);

    return ( 
        <Fragment>        
            <nav className="padding_1" style={{background:"#ffffff", height:"100px", padding:"15px 30px 0px 30px", boxShadow:"none"}}>
                <div className="nav-wrapper">
                    <a href="/" className="brand-logo"><img src={logo} alt="" className="imagen_index" /></a>
                    <a href="#!" data-target="mobile-demo" className="sidenav-trigger" style={{color:"black"}}><i className="material-icons">menu</i></a>
                    <ul className="left hide-on-med-and-down padding_2" style={{paddingLeft:"200px"}}>
                        <li><a href="/dra-paola-jacobo">Dra. Paola Jacobo</a></li>
                        <li><a href="/servicios">
                            <FormattedMessage
                                id="header.1"
                                defaultMessage="Servicios"
                            />
                        </a></li>
                        <li><a href="/galeria">
                            <FormattedMessage
                                id="header.2"
                                defaultMessage="Galeria"
                            />
                        </a></li>
                        <li><a href="/testimoniales">
                            <FormattedMessage
                                id="header.3"
                                defaultMessage="Testimoniales"
                            />
                        </a></li>
                        <li>
                            <a class='dropdown-trigger' href='#' data-target='citas'>
                                <FormattedMessage
                                    id="header.6"
                                    defaultMessage="Agenda una cita"
                                />
                            </a>
                        </li>
                        
                    </ul>

                    <ul id='citas' class='dropdown-content'>
                        <li><a style={{color:"#19b5cc"}} href="/contacto-guadalajara">
                            <FormattedMessage
                                id="header.4"
                                defaultMessage="Guadalajara"
                            />
                        </a></li>
                        <li><a style={{color:"#19b5cc"}} href="/contacto-vallarta">
                            <FormattedMessage
                                id="header.7"
                                defaultMessage="Nuevo Vallarta"
                            />
                        </a></li>
                    </ul>

                    <ul className="right hide-on-med-and-down padding_0" style={{paddingRight:"100px"}}>
                        
                        <li>
                            <div style={{display:"flex", flexDirection:"column", gap:"5px"}}>
                                <div className="boton">
                                    <a style={{height:"32px", display:"flex", justifyContent:"center", alignItems:"center"}} href="/contacto-guadalajara">
                                        <span>
                                            <FormattedMessage
                                                id="header.8"
                                                defaultMessage="cita"
                                            />
                                            {" "}Guadalajara
                                        </span>
                                    </a>
                                </div>
                                <div className="boton">
                                    <a style={{height:"32px", display:"flex", justifyContent:"center", alignItems:"center"}} href="/contacto-vallarta">
                                        <span>
                                            <FormattedMessage
                                                id="header.8"
                                                defaultMessage="cita"
                                            />
                                            {" "}Nv. Vallarta
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <a  target="blanck" href="https://wa.me/523320263649?text=¡Hola buen día! ¿Me puede brindar información de sus servicios?" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"80px"}}>
                                <div style={{width:"45px", height:"45px", display:"flex", justifyContent:"center", alignItems:"center", border:"solid 1px #707070", borderRadius:"50px", marginRight:"15px"}}>
                                    <i className="fas fa-phone-alt" style={{color:"#19B5CC"}}></i>
                                </div>
                                <div style={{height:"64px"}}>
                                    <div style={{height:"32px", position:"relative", top:"-25px"}}>
                                        <FormattedMessage
                                            id="header.5"
                                            defaultMessage="Contáctanos"
                                        />
                                    </div>
                                    <div style={{height:"32px", position:"relative", top:"-35px"}}>
                                        <span>+52 33 2026 3649</span><br />
                                        <span style={{position:"relative", top:"-45px"}}>+52 32 2349 0468</span>
                                        
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <div style={{height:"30px"}} onClick={() => {idioma.establecerLenguaje('es-MX')}}><img src={es} alt="" style={{width:"30px"}} /></div>
                            <div style={{height:"30px"}} onClick={() => {idioma.establecerLenguaje('en-US')}}><img src={en} alt="" style={{width:"30px"}} /></div>
                        </li>
                    </ul>
                </div>
            </nav>

            <ul className="sidenav" id="mobile-demo">
                <li><a href="/dra-paola-jacobo">Dra. Paola Jacobo</a></li>
                <li><a href="/servicios">
                    <FormattedMessage
                        id="header.1"
                        defaultMessage="Servicios"
                    />
                </a></li>
                <li><a href="/galeria">
                    <FormattedMessage
                        id="header.2"
                        defaultMessage="Galeria"
                    />
                </a></li>
                <li><a href="/testimoniales">
                    <FormattedMessage
                        id="header.3"
                        defaultMessage="Testimoniales"
                    />
                </a></li>
                <li><a href="/contacto-guadalajara">
                    <FormattedMessage
                        id="header.8"
                        defaultMessage="cita"
                    /> {" "}
                    <FormattedMessage
                        id="header.4"
                        defaultMessage="Guadalajara"
                    />
                </a></li>
                <li><a href="/contacto-vallarta">
                    <FormattedMessage
                        id="header.8"
                        defaultMessage="cita"
                    /> {" "}
                    <FormattedMessage
                        id="header.7"
                        defaultMessage="Nuevo Vallarta"
                    />
                </a></li>
            </ul>

        </Fragment>
    );
}
 
export default Header;
