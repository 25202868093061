import React, {Fragment, Component}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

class EditarImagen extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            file1: '',
            uploadValue: 0,
            picture: null,
            nombre: ''
        }
    }

    handelUpload = e => {
        e.preventDefault();

        this.setState({
            file1: e.target.files[0]
        }, () => {
            this.precarga();
        });
    }

    precarga = () => {
        var file = this.state.file1,
        imageType = /image.*/;
        
        if (!file.type.match(imageType))
        return;
    
        var reader = new FileReader();
    
        reader.onload = function(e){
            var result = e.target.result;
            document.getElementById("imgSalida").src = result;
        }
        
        reader.readAsDataURL(file);
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidUpdate = () => {
        if (this.state.nombre === '') {
            this.setState({
                nombre: this.props.servicios.nombre_esp + "-" + Math.random().toString(36).substr(2)
            });
        }
    }

    subirDB = async file => {
        const { firestore } = this.props;


        if (file === '1') {
            if ((this.state.file1 === '') || (this.state.nombre === '')) {
                Swal.fire(
                    'Error',
                    'No hay archivo seleccionado <br/>o <br/> Error en el nombre',
                    'error'
                )
            } else {
                Swal.fire({
                    title: '¿Esta seguro de actualizar este campo?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#2DCD22',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Continuar',
                    cancelButtonText: 'Cancelar',
                    reverseButtons: true
                }).then(async (result) => {
                    if (result.value) {
                        this.preloader();
                        var file1 = this.state.file1;

                        const storage = getStorage();
                        const storageRef = ref(storage, `/images/servicios/${this.props.servicios.nombre_esp}`);

                        const uploadTask = uploadBytesResumable(storageRef, file1);

                        // Register three observers:
                        // 1. 'state_changed' observer, called any time the state changes
                        // 2. Error observer, called on failure
                        // 3. Completion observer, called on successful completion
                        uploadTask.on('state_changed', 
                            (snapshot) => {
                                // Observe state change events such as progress, pause, and resume
                                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                const percentaje = (snapshot.bytesTransferred / snapshot.totalBytes) * 800;

                                this.setState({
                                    uploadValue: percentaje
                                })
                            }, 
                            (error) => {
                                // Handle unsuccessful uploads
                                Swal.fire(
                                    'Error',
                                    'Error al subir el archivo',
                                    'error'
                                )
                            }, 
                            () => {
                                // Handle successful uploads on complete
                                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    console.log('File available at', downloadURL);
                                    const servicios = {
                                        path: downloadURL
                                    }
                                    //guardado en la base de datos
                                    firestore.update({
                                        collection: 'servicios',
                                        doc: window.location.pathname.split('/')[3]
                                    }, servicios ).then(() => {
                                        Swal.fire(
                                            'Inserción exitosa',
                                            'Imagen insertada correctamente',
                                            'success'
                                        ).then(() => {
                                            window.location.replace(`/admin/imagenes`);
                                        });
                                    });
                                });
                            }
                        );

                    } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                    ) {
                        Swal.fire({
                            title: 'Cancelado',
                            text: 'El campo sigue intacto :)',
                            confirmButtonColor: '#2DCD22',
                            confirmButtonText: 'Continuar',
                            icon: 'error'
                        })
                    }
                })
            }
        }
    }

    preloader = () => {
        var preloader = 
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div><div class="gap-patch">
                    <div class="circle"></div>
                </div><div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>;
        ReactDOM.render(preloader, document.getElementById('preloader'));
    }

    render(){
        if (!this.props.servicios) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="EDITAR IMAGEN SERVICIO" />
                    <div className="container center-align" style={{marginTop:'50px'}}>
                        <div id="preloader" style={{display:"flex", justifyContent:"center", marginBottom:'30px', justifyItems:"center"}}>
                            
                        </div>
                        <div className="row">
                            <div className="col s4">    
                                <div className="file-field input-field">
                                    <div className="btn">
                                        <span>File</span>
                                        <input onChange={this.handelUpload} name="file" type="file"/>
                                    </div>
                                    <div className="file-path-wrapper">
                                        <input className="file-path validate" type="text" placeholder="Seleccione una foto o arrastre una foto"/>
                                    </div>
                                </div>
                                <div className=" btn-outline-light center" style={{padding: '30px 0 30px 0'}}>
                                    <div id="font" class="btn waves-effect waves-light" onClick={() => this.subirDB('1')} style={{padding:'0 50px 0 50px', background:'#235677 '}}>ACTUALIZAR</div>
                                    <Link to={`/admin/imagenes`} id="font" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginTop:'20px', background:'#235677 '}}>REGRESAR</Link>
                                </div>
                            </div>
                            <div className="col s8">
                                <img alt="imagen subida" id="imgSalida" width="100%" src={this.props.servicios.path}></img>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}

EditarImagen.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'servicios',
            doc: window.location.pathname.split('/')[3]
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        servicios: ordered.servicios && ordered.servicios[0]
    }))
)(EditarImagen);