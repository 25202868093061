import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import firebaseHelper from '../../../Helpers/Firebase';


class EditarServicio extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            informacion_esp: ""
        }
    }

    editar = e => {
        e.preventDefault();
    
        //extraer firestor de props
        const { firestore } = this.props;

        var editar = {...this.state}

        //actualizacion en la base de datos
        firebaseHelper.editar(this.props.servicios.id, 'servicios', firestore, editar, `/admin/servicios`);

    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidUpdate = () => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        if (this.state.informacion_esp === '') {
            this.setState({
                informacion_esp: this.props.servicios.informacion_esp,
                informacion_ing: this.props.servicios.informacion_ing,
                nombre_esp: this.props.servicios.nombre_esp,
                nombre_ing: this.props.servicios.nombre_ing,

            });
        }
    }

    informacion_esp = (e, editor) => {
        this.setState({
            informacion_esp: editor.getData()
        })
    }

    informacion_ing = (e, editor) => {
        this.setState({
            informacion_ing: editor.getData()
        })
    }

    render(){
        if (!this.props.servicios) {
            return(
                <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-red darken-4-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="EDITAR CATEGORIA" />
                    <div id="font-text" className="container center" style={{marginTop:'0px', marginBottom:'100px'}}>
                            <form onSubmit={this.editar}>
    
                                <div>
                                    <h3>Español</h3>
                                    <div style={{margin:'0 15%'}}>
                                        <div class="input-field col s12">
                                            <input required onChange={this.leerDato} defaultValue={this.state.nombre_esp} name="nombre_esp" id="nombre_esp" type="text" class="validate" />
                                            <label className="active" for="nombre_esp">Titulo</label>
                                        </div>
                                    </div>
                                    <div className="container" style={{textAlign:"left"}}>
                                        <h5>Información</h5>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data= {String(this.props.servicios.informacion_esp)}
                                            onInit={ editor => {
                                                // You can store the "editor" and use when it is needed.
                                                //console.log( 'Editor is ready to use!', editor );
                                            }}
                                            onChange={this.informacion_esp}
                                        />
                                    </div>
                                </div>

                                <div style={{marginTop:"100px", marginBottom:"100px"}}>
                                    <h3>Ingles</h3>
                                    <div style={{margin:'0 15%'}}>
                                        <div class="input-field col s12">
                                            <input required onChange={this.leerDato} defaultValue={this.state.nombre_ing} name="nombre_ing" id="nombre_ing" type="text" class="validate" />
                                            <label className="active" for="nombre_ing">Titulo</label>
                                        </div>
                                    </div>
                                    <div className="container" style={{textAlign:"left"}}>
                                        <h5>Información</h5>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data= {String(this.props.servicios.informacion_ing)}
                                            onInit={ editor => {
                                                // You can store the "editor" and use when it is needed.
                                                //console.log( 'Editor is ready to use!', editor );
                                            }}

                                            onChange={this.informacion_ing}
                                        />
                                    </div>
                                </div>

                                <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#235677 '}}>EDITAR</button>
                                <Link to={`/admin/servicios`} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#235677 '}}>REGRESAR</Link>
                            </form>
                    </div>
                </Fragment>
            );
        }
    }
}

EditarServicio.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'servicios',
            doc: window.location.pathname.split('/')[3]
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        servicios: ordered.servicios && ordered.servicios[0]
    }))
)(EditarServicio)