import React, {useEffect, useContext}  from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import M from 'materialize-css';
import { FormattedMessage } from 'react-intl';

const Galeria = props => {
    
    useEffect(() => {
        var elems = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(elems);
    });

    if (!props.galeria) {
        return (
            <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                <div class="preloader-wrapper big active">
                    <div class="spinner-layer spinner-red darken-4-only">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="gap-patch">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="contenedor center">
                <p className="titulo_3">
                    <FormattedMessage
                        id="header.2"
                        defaultMessage="Galeria"
                    />
                </p>
                <div className="padre">
                    {props.galeria.map(dato => (
                        <div className="contenedor_galeria_2">
                            <img className="materialboxed imagen_galeria" src={dato.path} alt="" />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
 
Galeria.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'galeria'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        galeria: ordered.galeria
    }))
)(Galeria);