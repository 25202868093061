import Swal from 'sweetalert2';
import { getStorage, ref, deleteObject } from "firebase/storage";

const firebaseHelper = {
    /**
     * 
     * @param {identificador del dato a eliminar} id 
     * @param {nombre de la coleccion a eliminar} collection 
     * @param {conexion de firebase} firestore 
     */
    eliminar: (id, collection, firestore) => {
        Swal.fire({
            title: '¿Esta seguro de eliminar este campo?',
            text: "No se podra recuperar!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                firestore.delete({
                    collection: collection,
                    doc: id
                }).then(() => {
                    Swal.fire({
                        title: 'Eliminado con exito',
                        text:'El campo fue eliminado exitosamente',
                        confirmButtonColor: '#2DCD22',
                        confirmButtonText: 'Continuar',
                        icon:'success'
                    })
                })
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    },
    /**
     * 
     * @param {identificador del dato a eliminar} id 
     * @param {nombre de la coleccion a eliminar} collection 
     * @param {conexion de firebase} firestore 
     * @param {conexion con firebase storage} firebase 
     * @param {URL de la imagen a eliminar} urlImagen 
     */
    eliminarImagen: (id, collection, firestore, firebase, urlImagen) => {
        const storage = getStorage();
        var desertRef;
        
        if (urlImagen !== '') {
            desertRef = ref(storage, urlImagen);
        }
        
        Swal.fire({
            title: '¿Esta seguro de eliminar este campo?',
            text: "No se podra recuperar!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                firestore.delete({
                    collection: collection,
                    doc: id
                }).then(() => {
                    if (urlImagen === '') {
                        Swal.fire({
                            title: 'Eliminación exitosa',
                            text:'El campo fue eliminado exitosamente',
                            confirmButtonColor: '#2DCD22',
                            confirmButtonText: 'Continuar',
                            icon:'success'
                        })
                    } else {
                        // Delete the file
                        deleteObject(desertRef).then(() => {
                            // File deleted successfully
                            Swal.fire({
                                title: 'Eliminación exitosa',
                                text:'El campo fue eliminado exitosamente',
                                confirmButtonColor: '#2DCD22',
                                confirmButtonText: 'Continuar',
                                icon:'success'
                            })
                        }).catch((error) => {
                            // Uh-oh, an error occurred!
                            //console.log("error => ", error);
                            Swal.fire({
                                title: 'Error',
                                text: 'Error al aliminar archivo 1',
                                confirmButtonColor: '#2DCD22',
                                confirmButtonText: 'Continuar',
                                icon: 'error'
                            })
                        });
                    }
                });
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    },
    /**
     * 
     * @param {nombre de la coleccion a eliminar} collection 
     * @param {conexion de firebase} firestore 
     * @param {datos a agregar} data 
     * @param {URL de redireccionamiento} url
     */
    agregar: (collection, firestore, data, url) => {
        firestore.add({ 
            collection: collection 
        }, data).then(() => {
            Swal.fire(
                'Inserción exitosa!',
                'Campo agregado correctamente',
                'success'
            ).then(() => {
                window.location.replace(url);
                // history.push(url)
            });
        });
    },
    /**
     * 
     * @param {identificador del dato a modificar} id 
     * @param {nombre de la coleccion a editar} collection 
     * @param {conexion con firebase} firestore 
     * @param {datos a editar} data 
     * @param {URL de redireccionamiento} url 
     * @param {conexion con funcion history} history 
     */
    editar: (id, collection, firestore, data, url) => {
        Swal.fire({
            title: '¿Esta seguro de actualizar este campo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                firestore.update({
                    collection: collection,
                    doc: id
                }, data ).then(() => {
                    Swal.fire({
                        title: 'Modificacion exitosa',
                        text:'El campo fue modificado exitosamente',
                        confirmButtonColor: '#2DCD22',
                        confirmButtonText: 'Continuar',
                        icon:'success'
                    }).then(() => {
                        //redireccion
                        window.location.replace(url);
                        // history.push(url)
                    })
                });
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                })
            }
        })
    },
    /**
     * 
     * @param {nombre de la coleccion a eliminar} collection 
     * @param {conexion de firebase} firestore 
     * @param {datos a agregar} data 
     */
     agregarSin: async (collection, firestore, data) => {
        firestore.add({ 
            collection: collection 
        }, data).then(async () => {
            window.gtag_report_conversion('/gracias');
            // window.location.replace("/gracias")
            // Swal.fire(
            //     'Mensaje exitoso',
            //     'Nos pondremos en contacto con usted',
            //     'success'
            // )
            return await true;
        });
    }
}

export default firebaseHelper;