import React, {useEffect, useContext, Fragment}  from 'react';
import { langContext } from '../Context/langContext';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import M from 'materialize-css';
import { FormattedMessage } from 'react-intl';

import img10 from "../Images/10.png";
import img11 from "../Images/11.png";


const Testimoniales = props => {
    const idioma = useContext(langContext);
    const fondo = ["#22639A", "#2A6E8A", "#19b5cc", "#19b4cc"];

    useEffect(($_x,_s,id) => {
        var elems = document.querySelectorAll('.carousel');
        M.Carousel.init(elems, {
            fullWidth: true,
            indicators: true
        });
    });

    const izquierda = () => {
        var elem = document.querySelector('.carousel');
        var instance = M.Carousel.getInstance(elem);
        instance.prev(1);
    }

    const derecha = () => {
        var elem = document.querySelector('.carousel');
        var instance = M.Carousel.getInstance(elem);
        instance.next(1);
    }

    if (!props.testimoniales) {
        return (
            <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                <div class="preloader-wrapper big active">
                    <div class="spinner-layer spinner-red darken-4-only">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="gap-patch">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <Fragment>
                <div className="fondo_6"></div>
                <div className="container center">
                    <p className="titulo_3">
                        <FormattedMessage
                            id="header.3"
                            defaultMessage="Testimoniales"
                        />
                    </p>

                    <div className="container">
                        <div class="carousel carousel-slider center" style={{height:"300px"}}>
                            <div class="item left">
                                <i class="fas fa-arrow-circle-left" style={{fontSize:"40px",position:"relative", zIndex:"9999", color:"#235677"}} onClick={derecha}></i>
                            </div>
                            <div class="right">
                                <i class="fas fa-arrow-circle-right" style={{fontSize:"40px", position:"relative", zIndex:"9999", color:"#235677"}} onClick={izquierda}></i>
                            </div>
                            {props.testimoniales.map(dato => (
                                <div class="carousel-item" href="#one!">
                                    <div className="padre">
                                        <div className="testimonial_letra" style={{background:fondo[Math.floor(Math.random() * (3 - 0)) + 0], color:"#ffffff"}}>
                                            <h1 style={{margin:"0px", padding:"0px"}}>{dato.nombre.charAt(0)}</h1>
                                        </div>
                                    </div>
                                    <h2 style={{color:"#235677"}}>{dato.nombre}</h2>
                                    {idioma.obtenerLenguaje() === "es-MX" ? (
                                        <p>{dato.testimonio_esp}</p>
                                    ) : (
                                        <p>{dato.testimonio_ing}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="container center" style={{marginBottom:"100px"}}>
                        <a target="blanck" href="https://www.doctoralia.com.mx/paola-y-jacobo-oceguera/oftalmologo/puerto-vallarta">
                            <p className="titulo_1"><FormattedMessage
                                id="testimoniales.1"
                                defaultMessage="Perfil "
                            /> Doctoralia</p>
                            <img src={img11} alt="" style={{width:"100%"}} />
                        </a>
                    </div>
                </div>
            </Fragment>
        )
    }
}

Testimoniales.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'testimoniales'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        testimoniales: ordered.testimoniales
    }))
)(Testimoniales);
