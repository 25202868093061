import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import firebaseHelper from '../../../Helpers/Firebase';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import M from 'materialize-css';


class Imagenes extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            carga: true
        }
    }

    componentDidUpdate = prevProps => {
        console.log(this.props);
        if (this.props.galeria !== undefined && this.state.carga) {
            var elems = document.querySelectorAll('.materialboxed');
            M.Materialbox.init(elems);
            this.setState({
                carga: false
            });
        }
    }

    eliminar = (id, path) => {
        const { firestore } = this.props;
        const { firebase } = this.props;

        firebaseHelper.eliminarImagen(id, 'galeria', firestore, firebase, path);
        
    }

    render(){
        if (!this.props.galeria) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="IMAGENES" />

                    {this.props.servicios === undefined ? (
                        window.location.reload()
                    ) : (    
                        this.props.servicios.length === 0 ? (
                            <div className="center">
                                <h2>No hay servicios registrados</h2>
                            </div>
                        ) : (
                            <div className="container">
                                <h3>Servicios</h3>
                                <Table striped bordered hover style={{marginBottom:'50px'}}>
                                    <thead>
                                        <tr>
                                            <th>Imagen</th>
                                            <th>Titulo</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody id="font">
                                        {this.props.servicios.map(dato => (
                                            <tr key={dato.id}>
                                                <td><img className="materialboxed" src={dato.path} alt="" style={{width:"100px"}} /></td>
                                                <td>{dato.nombre_esp}</td>
                                                <td>
                                                    {dato.path === "" ||  dato.path === undefined ? (
                                                        <a style={{marginLeft:'14%'}} href={`/admin/nueva-imagen-servicio/${dato.id}`}><i class="material-icons" style={{color:'#235677 '}}>add_circle</i></a>
                                                    ) : (
                                                        <a style={{marginLeft:'14%'}} href={`/admin/editar-imagen-servicio/${dato.id}`}><i class="material-icons" style={{color:'#235677 '}}>create</i></a>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        )
                    )}

                    <div className="btn-outline-light container" style={{padding: '100px 0px 0px 0px'}}>
                        <Link to={`/admin/nueva-imagen-galeria`} id="font" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', background:'#235677 '}}>AGREGAR IMAGEN GALERIA</Link>
                    </div>
                    {this.props.galeria.length === 0 ? (
                        <div className="center">
                            <h2>No hay imagenes registrados</h2>
                        </div>
                    ) : (
                        <div className="container">
                            <h3>Galeria</h3>
                            <Table striped bordered hover style={{marginBottom:'50px'}}>
                                <thead>
                                    <tr>
                                        <th>Imagen</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody id="font">
                                    {this.props.galeria.map(dato => (
                                        <tr key={dato.id}>
                                            <td><img className="materialboxed" src={dato.path} alt="" style={{width:"100px"}} /></td>
                                            <td>
                                                <div onClick={ () => this.eliminar(dato.id, dato.path)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#235677 '}}>delete</i></div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                    
                </Fragment>
            );
        }
    }
}

Imagenes.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'servicios'
        },
        {
            collection: 'galeria'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        galeria: ordered.galeria,
        servicios: ordered.servicios
    }))
)(Imagenes);