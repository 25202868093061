import React, {Fragment, Component}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import firebaseHelper from '../../../Helpers/Firebase';


class NuevoTestimonial extends Component {
    
    constructor(props){
        super(props)
        this.state = {}
    }

    componentDidUpdate = () => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }

    agregar = e => {
        const { history } = this.props;
        e.preventDefault();
    
        //extraer firestor de props
        const { firestore } = this.props;

        //guardado en la base de datos
        var nuevo = {...this.state}

        firebaseHelper.agregar('testimoniales', firestore, nuevo, `/admin/testimoniales`, history);
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    render(){
        return (
            <Fragment>
                <Header mensaje="NUEVO TESTIMONIAL" />
                <div id="font" className="container">    
                    <div className="center" style={{padding: '30px 0 30px 0', margin:'0 15%'}}>
                        <form onSubmit={this.agregar}>
                            <div>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="nombre" id="nombre" type="text" class="validate" />
                                    <label for="nombre">Nombre</label>
                                </div>
                            </div>
                            <div>
                                <h3>Español</h3>
                                <div class="input-field col s12">
                                    <textarea required onChange={this.leerDato} name="testimonio_esp" id="testimonio_esp" class="materialize-textarea"></textarea>
                                    <label for="testimonio_esp">Textarea</label>
                                </div>
                            </div>

                            <div style={{marginTop:"50px"}}>
                                <h3>Ingles</h3>
                                <div class="input-field col s12">
                                    <textarea required onChange={this.leerDato} name="testimonio_ing" id="testimonio_ing" class="materialize-textarea"></textarea>
                                    <label for="testimonio_ing">Textarea</label>
                                </div>
                            </div>

                            <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#235677 ', marginTop:'25px'}}>AGREGAR</button>
                            <Link to={`/admin/testimoniales`} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#235677 ', marginTop:'25px'}}>REGRESAR</Link>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
}

NuevoTestimonial.protoType = {
    firestor : PropTypes.object.isRequired
}

export default firestoreConnect()(NuevoTestimonial);