import React, {Fragment, useContext}  from 'react';
import { langContext } from '../Context/langContext';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import img9 from "../Images/9.png";

const Servicios = props => {
    const idioma = useContext(langContext);

    console.log(props);


    if (!props.servicios) {
        return (
            <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                <div class="preloader-wrapper big active">
                    <div class="spinner-layer spinner-red darken-4-only">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="gap-patch">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <Fragment>
                <div className="fondo_5"></div>
                <div className="container center" style={{padding:"50px 0px"}}>
                    <p className="titulo_3">
                        <FormattedMessage
                            id="index.25"
                            defaultMessage="servicios"
                        />
                    </p>
                    <div className="padre">
                        {props.servicios.map(dato => (
                            <div className="hijo_servicios">
                                <div>
                                    <img src={dato.path} alt="" style={{width:"100%"}} />
                                </div>
                                <div className="titulo_servicios">
                                    {idioma.obtenerLenguaje() === "es-MX" ? (
                                        <p><span>{dato.nombre_esp}</span></p>
                                    ) : (
                                        <p><span>{dato.nombre_ing}</span></p>
                                    )}
                                </div>
                                <div className="boton" style={{width:"100px"}}>
                                    <a href={`/servicios/${dato.id}`}>
                                        <p>
                                            <FormattedMessage
                                                id="servicios.1"
                                                defaultMessage="Ver más"
                                            />
                                        </p>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Fragment>
        );
    }
}
 
Servicios.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'servicios'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        servicios: ordered.servicios
    }))
)(Servicios);

